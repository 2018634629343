export default function Chili({ size = 48 }) {
  return (
    <svg
      height={size}
      viewBox="0 0 744.09437 793.70082"
      width={Math.round((size * 210) / 224)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ display: "inline" }} transform="translate(0,49.606298)">
        <path
          d="m 607.26876,125.04665 c -5.20335,2.29021 -10.40671,4.58042 -15.61006,6.87064 -0.62279,3.9339 0.70378,6.7652 4.51678,8.19012 7.5761,4.78803 15.06888,9.68439 21.402,16.08838 4.17688,3.26924 7.71437,7.06785 10.0099,11.89463 7.06358,11.84373 14.34796,23.55259 21.60591,35.27758 9.25864,-8.34086 13.2104,2.87389 18.67282,8.05991 10.96238,8.05336 21.92474,16.10673 32.88711,24.1601 3.88125,-0.65287 7.76251,-1.30574 11.64377,-1.95861 -0.45813,-9.03368 -5.99122,-16.37451 -7.03206,-25.13454 -1.34055,-10.67824 -13.36745,-16.70237 -12.46735,-27.93185 -1.0924,-9.39339 -5.84191,-18.09019 -6.32327,-27.50888 8.9245,-8.31893 5.22321,-22.74431 9.63854,-33.77017 4.24152,-11.61779 8.99379,-23.344725 11.39175,-35.449545 -0.93329,-12.6196 0.0336,-25.32524 1.79724,-37.785542 -1.72471,-9.37833 -3.19402,-18.94989 -7.13707,-27.64876 -5.36245,-10.6537108 -14.22848,-19.02712085 -22.3201,-27.6530688 -5.66291,-5.8319652 -13.30175,-9.5383472 -20.6847,-11.7891782 -2.26435,-5.721968 -13.25421,-10.780774 -12.37825,-1.522333 0.63377,5.164418 -15.17079,15.1882082 -2.77382,15.1282122 15.57725,-1.842505 29.23296,10.4525269 33.31018,24.8653178 5.04217,13.56343 2.16557,28.07777 -0.67624,41.770572 0.68126,9.7755 -5.75306,17.23344 -9.38226,25.79509 -2.00533,11.7545 -10.45258,20.493385 -14.18462,31.312235 -2.04246,12.02063 -17.4042,4.58153 -25.85859,6.91192 -6.70442,0.24713 -13.44083,0.59164 -20.04761,1.82777 z"
          style={{
            display: "inline",
            fill: "#89a754",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "white",
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeOpacity: 1,
            strokeWidth: 2,
          }}
        />
      </g>
      <g style={{ display: "inline" }} transform="translate(0,49.606298)">
        <path
          d="m 667.37351,175.5684 c 2.301,7.63983 4.16438,15.43157 8.40085,22.28318 l 5.53491,10.70698 c 0.11412,10.70167 9.12667,17.26549 17.36371,23.17263 9.97454,4.50427 14.25034,2.70985 11.99966,-3.13816 -0.60999,-7.17143 -4.83949,-11.0694 -5.91312,-17.95845 -0.78313,-5.02503 -0.77378,-3.1858 -0.78313,-5.02503 -3.12926,-8.70917 -12.60684,-14.50971 -11.84642,-24.49359 -1.10419,-9.39242 -5.9603,-19.11306 -6.49515,-28.52635 5.82197,-4.71449 6.10534,-12.22386 7.04301,-19.47749 1.10222,-13.22916 7.19149,-24.56068 10.74265,-37.19239 3.54206,-7.45444 3.55996,-15.52674 3.15127,-23.68186 -0.76641,-10.07453 2.98103,-20.702075 1.46551,-29.94582 -2.58348,-10.19573 -3.76199,-19.67948 -9.82184,-28.84831 -4.51904,-7.7975701 -10.9861,-13.45692012 -17.3324,-20.236693 7.64719,11.71157 13.5071,24.451613 14.98961,38.478203 3.40079,19.99262 -3.21076,39.51573 -7.38618,58.81118 -3.61162,26.30709 -9.29842,52.44068 -19.26855,77.12759 -0.85521,2.5689 -1.94372,5.1841 -1.84439,7.94438 z"
          style={{
            display: "inline",
            fill: "#657b3e",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeOpacity: 1,
            strokeWidth: 2,
          }}
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
        transform="translate(0,49.606298)"
      >
        <path
          d="m 17.190877,657.39003 c -2.26053,23.87612 18.60891,49.59685 39.98001,59.43405 26.58784,9.16083 55.840493,8.49889 83.280583,3.57891 41.14814,-18.0134 87.4078,-14.056 130.96023,-21.49226 20.97337,-11.15895 44.54363,-16.39672 64.64614,-29.51382 36.255,-25.06344 64.0191,-60.36724 100.98932,-84.39344 13.19887,-16.20956 34.07715,-21.43455 47.97063,-37.04006 13.54018,-16.38947 31.95682,-27.5356 47.05615,-41.1103 13.72953,-20.44326 40.65805,-26.22244 56.72098,-44.78229 21.95086,-21.0452 52.9171,-30.03898 72.82632,-53.40866 23.96908,-20.70171 46.03319,-46.23124 53.4815,-77.71592 9.96312,-31.59127 24.36138,-70.65003 -0.0467,-96.45681 -24.63055,-7.68991 -42.98436,-41.7583 -65.71964,-35.65602 -10.12425,-27.57516 -32.93085,-51.17375 -57.38794,-64.9161 10.95854,-9.09378 21.08558,-5.77576 -0.77509,-15.0847 -34.21293,-2.21921 -61.32174,25.62126 -76.76488,50.17908 -19.59415,29.19779 -23.98049,56.96422 -38.20617,84.02746 -11.87379,24.53655 -29.38437,45.9726 -40.06429,71.22693 -15.69821,26.32036 -50.21706,40.32788 -61.60548,66.96833 -9.89011,17.56097 -39.94121,48.99293 -63.25391,64.1635 -9.06689,5.26357 -37.25643,44.94025 -57.00419,66.82711 -22.26879,28.82433 -55.6661,40.12115 -89.48209,49.04657 -33.05057,18.15866 -60.85478,44.60541 -93.428443,62.89285 -18.34456,8.59627 -31.10441,26.33065 -54.17304,23.22559 z"
          style={{
            display: "inline",
            fill: "#e00522",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "#000000",
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeOpacity: 1,
            strokeWidth: 2,
          }}
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
        transform="translate(0,49.606298)"
      >
        <path
          d="m 108.51017,722.44908 c 0,0 10.39407,1.17746 31.40559,-3.02871 41.14814,-18.0134 87.85422,-14.056 131.40665,-21.49226 20.97337,-11.15895 44.25411,-16.551 64.35662,-29.6681 36.255,-25.06344 66.13132,-60.90295 101.31583,-84.75058 13.19887,-16.20956 33.1193,-20.57234 47.01278,-36.17785 13.54018,-16.38947 32.20936,-27.78813 47.30869,-41.36283 13.72953,-20.44326 41.03685,-26.47498 57.09978,-45.03483 21.95086,-21.0452 52.79083,-29.78644 72.70005,-53.15612 23.96908,-20.70171 45.40185,-45.47363 52.85016,-76.95831 9.96312,-31.59127 25.11899,-69.63988 0.71091,-95.44666 -4.31095,-1.00737 -9.87489,-4.37497 -15.04399,-8.12251 -10.68881,-7.7493 -16.22359,-15.11019 -15.54886,-10.49358 3.58877,24.55481 -3.58968,64.73099 -14.07221,74.70521 -17.84659,16.98119 -38.39057,42.49382 -46.36243,64.19972 -9.8733,26.88318 -32.28559,48.48154 -60.77828,67.43347 -27.63096,18.37876 -55.3051,36.52226 -71.8955,63.95655 -11.70983,19.36365 -32.62211,30.63617 -45.53073,47.01382 -25.52457,32.384 -45.86626,68.03895 -93.47431,93.46144 -31.92837,17.0496 -30.72944,27.50957 -55.42743,41.44411 -23.61107,13.32132 -38.13479,24.13519 -68.16016,26.60932 -49.42351,4.07255 -88.03418,19.79098 -119.87316,26.8687 z"
          style={{
            display: "inline",
            fill: "#ba041c",
            fillOpacity: 1,
            fillRule: "evenodd",
            stroke: "none",
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            strokeOpacity: 1,
            strokeWidth: 2,
          }}
        />
      </g>
      <g
        style={{
          display: "inline",
        }}
        transform="translate(0,49.606298)"
      >
        <path
          d="m 87.51187,659.14345 c 46.73344,-6.6758 43.8195,5.26673 64.25584,-9.81915 19.89661,-14.68748 47.36511,-30.30342 64.56474,-35.30408 28.03846,-8.93764 43.77317,-34.15392 70.42866,-44.88087 19.10077,-19.57338 39.82766,-35.95596 60.87906,-53.14382 19.43796,-17.66016 44.70594,-28.16466 52.64296,-56.40112 13.17073,-23.53851 43.45863,-29.06743 47.6416,-59.99427 9.60327,-21.63391 39.73868,-22.75775 38.79151,-51.85269 4.00195,-27.44038 42.13164,-9.3999 41.51753,-41.55024 2.01168,-24.77125 14.22976,-46.01466 24.60057,-67.75771 8.25503,-16.11567 17.27219,-31.78773 25.51187,-47.91223 -11.99149,-15.3934 -17.70109,27.68413 -32.4212,31.79997 -20.00305,24.30888 -33.84419,53.17792 -48.12796,81.33565 -36.04399,0.86937 -31.96569,47.50734 -50.03378,66.56013 -22.24149,14.2304 -44.63041,30.96854 -52.96632,58.00024 -14.00347,22.24675 -38.58927,35.90032 -48.27935,61.36869 -15.70888,22.27762 -52.29947,22.61115 -58.05734,53.77762 -15.679,25.80483 -56.62561,18.43759 -66.19182,51.10348 -18.7439,17.62379 -48.97378,14.04741 -75.99358,48.05522 -7.06766,8.1569 -50.321587,10.08226 -58.76299,16.61518 z"
          style={{
            fill: "#fdaab5",
            fillOpacity: 0.66331661,
            fillRule: "evenodd",
            stroke: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
            strokeWidth: 0.94275761,
          }}
        />
      </g>
    </svg>
  );
}
