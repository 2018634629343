import { useEffect, useState } from "react";

export default function useDebounce(value, delay = 250) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
    // return () => clearTimeout(setTimeout(() => setDebouncedValue(value), delay));
  }, [value, delay]);
  return debouncedValue;
}
