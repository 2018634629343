import { useHistory } from "react-router-dom";

import { useOrders } from "./OrdersContext.js";
import { useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";

export default function Orders() {
  const [orders] = useOrders();
  const reversedOrders = orders.orders?.slice?.()?.reverse?.();

  return (
    <Div>
      <h1
        style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        Order History
      </h1>
      <Div style={{ overflowY: "auto" }}>
        {reversedOrders?.map?.((order) => (
          <OrderRow key={order.id} order={order} />
        ))}
      </Div>
    </Div>
  );
}

function OrderRow({ order }) {
  const { backgroundColorBody, colorBody, colorSecondary } = useStyles();
  const history = useHistory();
  const { id, timePlaced: placed, total } = order;

  return (
    <Div
      style={{
        flexBasis: "auto",
        flexGrow: 0,
        marginBottom: 16,
        marginLeft: 16,
        marginRight: 16,
      }}
    >
      <Pressable
        innerStyle={{
          alignItems: "center",
          color: colorBody,
          flexBasis: 0,
          flexGrow: 1,
          fontSize: 24,
          justifyContent: "space-between",
          transformOrigin: "center left",
        }}
        onPress={() => history.push(`order/${order.id}`)}
        outerStyle={{
          backgroundColor: backgroundColorBody,
          paddingBottom: 16,
          paddingTop: 16,
        }}
      >
        {id}
        <Div
          style={{
            alignItems: "flex-end",
            color: colorSecondary,
            flexBasis: "auto",
            flexDirection: "column",
            flexGrow: 0,
            fontSize: 16,
          }}
        >
          <Div style={{ flexBasis: "auto", flexGrow: 0, marginBottom: 8 }}>
            {placed.toLocaleString()}
          </Div>
          <Div style={{ flexBasis: "auto", flexGrow: 0 }}>${total}</Div>
        </Div>
      </Pressable>
    </Div>
  );
}
