import React, { createContext, useContext, useEffect, useState } from "react";

import Div from "./components/Div.js";

const HoursContext = createContext();

export function HoursProvider({ children }) {
  const [cookingTimeOffset, setCookingTimeOffset] = useState();
  const [deliveryTimeOffset, setDeliveryTimeOffset] = useState();
  const [holidays, setHolidays] = useState();
  const [loaded, setLoaded] = useState(false);
  const [weeklyHours, setWeeklyHours] = useState();

  useEffect(_rehydrate, []);

  if (!loaded) {
    return (
      <Div style={{ alignItems: "center", justifyContent: "center" }}>
        Loading hours
      </Div>
    );
  }

  return (
    <HoursContext.Provider
      value={{ cookingTimeOffset, deliveryTimeOffset, holidays, weeklyHours }}
    >
      {children}
    </HoursContext.Provider>
  );

  function _fetch({ lastModified } = {}) {
    const controller = new AbortController();

    (async function ({ prevLastModified }) {
      try {
        const response = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://${process.env.REACT_APP_apiBase}/hours`
            : `http://${process.env.REACT_APP_apiBase}:8080/hours`,
          !!prevLastModified
            ? {
                body: prevLastModified,
                headers: {
                  "Content-Type": "text/plain",
                },
                method: "POST",
                signal: controller.signal,
              }
            : {
                method: "POST",
                signal: controller.signal,
              }
        );
        if (response.status === 304) {
          return;
        }
        const lastModified = response.headers.get("Last-Modified");
        const decodedResponse = await response.json();
        localStorage.setItem("hours", JSON.stringify(decodedResponse));
        localStorage.setItem("hoursModified", lastModified);
        setCookingTimeOffset(decodedResponse.cookingTimeOffset);
        setDeliveryTimeOffset(decodedResponse.deliveryTimeOffset);
        setHolidays(
          decodedResponse.holidays.reduce(
            (acc, cur) => [
              ...acc,
              {
                beginning: new Date(cur.beginning),
                end: new Date(cur.end),
              },
            ],
            []
          )
        );
        setWeeklyHours(decodedResponse.weeklyHours);
        setLoaded(true);
      } catch (error) {
        console.error(error);
      }
    })({ prevLastModified: lastModified });

    return () => controller.abort();
  }

  function _rehydrate() {
    const hours = JSON.parse(localStorage.getItem("hours"));
    if (hours === null) {
      _fetch();
      return;
    }
    setCookingTimeOffset(hours.cookingTimeOffset);
    setDeliveryTimeOffset(hours.deliveryTimeOffset);
    setHolidays(
      hours.holidays.reduce(
        (acc, cur) => [
          ...acc,
          {
            beginning: new Date(cur.beginning),
            end: new Date(cur.end),
          },
        ],
        []
      )
    );
    setWeeklyHours(hours.weeklyHours);
    setLoaded(true);
    _fetch({ lastModified: localStorage.getItem("hoursModified") });
  }
}

export function useHours() {
  const context = useContext(HoursContext);
  if (context === undefined) {
    throw new Error("useHours must be used within a HoursProvider");
  }
  return context;
}
