import {
  forwardRef,
  // useCallback,
  // useRef,
  useState,
} from "react";

import useViewport from "../lib/useViewport.js";

export default forwardRef(function Pressable(
  {
    children,
    disabled = false,
    innerStyle,
    onDisabledPress,
    // onDoublePress,
    // onLongPress,
    onPress,
    onPressIn,
    onPressOut,
    outerStyle,
    pressDurationBreakpoint = 500,
    pressScale = 1.5,
    ...props
  },
  ref
) {
  // const height = outerStyle?.height || undefined;
  const { mobile } = useViewport();
  // const pressTimeout = useRef();
  // const pressedRef = useRef();
  const [pressed, setPressed] = useState(false);
  // const target = useRef();
  const width = outerStyle?.width || undefined;

  const innerStyles = {
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    MsUserSelect: "none",
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    borderWidth: 0,
    boxSizing: "border-box",
    display: "flex",
    flexBasis: "auto",
    flexDirection: "row",
    flexGrow: 0,
    flexShrink: 0,
    fontSize: 16,
    fontWeight: pressed ? "bold" : "normal",
    transform: pressed ? `scale(${pressScale})` : undefined,
    transitionDuration: 600,
    transitionProperty: "all",
    transitionTiming: "ease-in-out",
    justifyContent: "flex-start",
    overflowX: "hidden",
    overflowY: "hidden",
    overscrollBehavior: "none",
    userSelect: "none",
    ...innerStyle,
  };
  const outerStyles = {
    alignItems: "center",
    borderWidth: 0,
    // boxShadow: pressed
    //   ? "0 14px 28px rgba(0, 0, 0, 0.75), 0 10px 10px rgba(0, 0, 0, 0.66)"
    //   : "0 1px 3px rgba(0, 0, 0, 0.36), 0 1px 2px rgba(0, 0, 0, 0.72)",
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexBasis: "auto",
    flexDirection: "row",
    flexGrow: 0,
    flexShrink: 0,
    // height,
    justifyContent: "center",
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    // maxHeight: height,
    // maxWidth: width,
    // minHeight: height,
    // minWidth: width,
    overflowX: "hidden",
    // overflowY: "hidden",
    overscrollBehavior: "none",
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    width,
    ...outerStyle,
  };

  // const clear = useCallback((event, shouldTriggerClick = true) => {
  //   event.stopPropagation();
  //   pressedRef.current = false;
  //   setPressed(false);
  //   if (mobile && target.current) {
  //     target.current.removeEventListener("contextmenu", _preventDefault);
  //     target.current.removeEventListener("touchend", _preventDefault);
  //   }
  //   if (mobile) {
  //     onPressOut?.(event);
  //   }
  // });

  // const start = useCallback((event) => {
  //   event.stopPropagation();
  //   pressedRef.current = true;
  //   setPressed(true);
  //   if (event.target) {
  //     if (mobile) {
  //       event.target.addEventListener("contextmenu", _preventDefault);
  //       event.target.addEventListener("touchend", _preventDefault, {
  //         passive: false,
  //       });
  //       onPressIn?.(event);
  //     }
  //     target.current = event.target;
  //   }
  //   if (pressTimeout.current !== null && pressTimeout.current !== undefined) {
  //     clearTimeout(pressTimeout.current);
  //     pressTimeout.current = null;
  //     setPressed(false);
  //     onDoublePress?.(event);
  //     return;
  //   }
  //   pressTimeout.current = setTimeout(() => {
  //     clearTimeout(pressTimeout.current);
  //     pressTimeout.current = null;
  //     setPressed(false);
  //     if (pressedRef.current) {
  //       onLongPress?.(event);
  //       return;
  //     }
  //     onPress?.(event);
  //   }, pressDurationBreakpoint);
  // });

  return mobile ? (
    <button
      onClick={(event) => {
        if (disabled) {
          onDisabledPress?.(event);
          return;
        }
        onPress(event);
        setPressed(false);
      }}
      onTouchEnd={(event) => {
        if (disabled) {
          return;
        }
        setPressed(false);
        onPressOut?.(event);
      }}
      onTouchStart={(event) => {
        if (disabled) {
          return;
        }
        setPressed(true);
        onPressIn?.(event);
      }}
      // onTouchEnd={clear}
      // onTouchMove={() => {
      //   clearTimeout(pressTimeout.current);
      //   pressTimeout.current = null;
      //   setPressed(false);
      // }}
      // onTouchStart={start}
      ref={ref}
      style={outerStyles}
      {...props}
    >
      <div style={innerStyles}>{children}</div>
      {
        //pressedRef.current
      }
    </button>
  ) : (
    <button
      onClick={(event) => {
        if (disabled) {
          onDisabledPress?.(event);
          return;
        }
        onPress(event);
        setPressed(false);
      }}
      onMouseOut={(event) => {
        if (disabled) {
          return;
        }
        setPressed(false);
        onPressOut?.(event);
      }}
      onMouseOver={(event) => {
        if (disabled) {
          return;
        }
        setPressed(true);
        onPressIn?.(event);
      }}
      // onDrag={() => {
      //   clearTimeout(pressTimeout.current);
      //   pressTimeout.current = null;
      //   setPressed(false);
      // }}
      // onMouseDown={start}
      // onMouseLeave={(event) => clear(event, false)}
      // onMouseOut={onPressOut}
      // onMouseOver={onPressIn}
      // onMouseUp={clear}
      ref={ref}
      style={outerStyles}
      {...props}
    >
      <div style={innerStyles}>{children}</div>
      {
        //pressedRef.current
      }
    </button>
  );

  // function _preventDefault(event) {
  //   event.cancelable && event.preventDefault();
  // }
});
