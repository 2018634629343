import React, { createContext, useEffect, useReducer } from "react";

const CartStateContext = createContext();
const CartDispatchContext = createContext();

export function CartProvider({ children }) {
  const [state, dispatch] = useReducer(persistingReducer, { items: {} });

  useEffect(_rehydrate, []);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );

  function _rehydrate() {
    dispatch({
      payload: JSON.parse(localStorage.getItem("cart")),
      type: "REHYDRATE",
    });
  }
}

export function decreaseItemQuantity({ dispatch, id }) {
  dispatch({ payload: { id }, type: "DECREASE_ITEM_QUANTITY" });
}

export function decreaseItemSecondaryQuantity({ dispatch, id }) {
  dispatch({ payload: { id }, type: "DECREASE_ITEM_SECONDARY_QUANTITY" });
}

export function decreaseItemSideQuantity({ dispatch, item, side }) {
  dispatch({ payload: { item, side }, type: "DECREASE_ITEM_SIDE_QUANTITY" });
}

export function increaseItemQuantity({ dispatch, id }) {
  dispatch({ payload: { id }, type: "INCREASE_ITEM_QUANTITY" });
}

export function increaseItemSecondaryQuantity({ dispatch, id }) {
  dispatch({ payload: { id }, type: "INCREASE_ITEM_SECONDARY_QUANTITY" });
}

export function increaseItemSideQuantity({ dispatch, item, side }) {
  dispatch({ payload: { item, side }, type: "INCREASE_ITEM_SIDE_QUANTITY" });
}

export function resetCart({ dispatch }) {
  dispatch({ type: "RESET_CART" });
}

export function setItemQuantity({ dispatch, id, quantity }) {
  dispatch({ payload: { id, quantity }, type: "SET_ITEM_QUANTITY" });
}

export function setItemSecondaryQuantity({ dispatch, id, secondaryQuantity }) {
  dispatch({
    payload: { id, secondaryQuantity },
    type: "SET_ITEM_SECONDARY_QUANTITY",
  });
}

export function setSpecialRequests({ dispatch, id, specialRequests }) {
  dispatch({ payload: { id, specialRequests }, type: "SET_SPECIAL_REQUESTS" });
}

export function useCart() {
  return [useCartState(), useCartDispatch()];
}

function persistingReducer(state, action) {
  const newState = reducer(state, action);
  localStorage.setItem("cart", JSON.stringify(newState));
  return newState;
}

function reducer(state, action) {
  switch (action.type) {
    case "DECREASE_ITEM_QUANTITY": {
      const { id } = action.payload;
      if (!state.items[id]) {
        return state;
      }
      if (state.items[id].quantity === 1) {
        if (state.items[id].secondaryQuantity === undefined) {
          const items = { ...state.items };
          delete items[id];
          return {
            ...state,
            items,
          };
        }
        const item = { ...state.items[id] }; // untested
        delete item.quantity;
        return {
          ...state,
          items: {
            ...state.items,
            [id]: item,
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            quantity: state.items[id].quantity - 1,
          },
        },
      };
    }
    case "DECREASE_ITEM_SECONDARY_QUANTITY": {
      const { id } = action.payload;
      if (!state.items[id]) {
        return state;
      }
      if (state.items[id].secondaryQuantity === 1) {
        if (state.items[id].quantity === undefined) {
          const items = { ...state.items };
          delete items[id];
          return {
            ...state,
            items,
          };
        }
        const item = { ...state.items[id] }; // untested
        delete item.secondaryQuantity;
        return {
          ...state,
          items: {
            ...state.items,
            [id]: item,
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            secondaryQuantity: state.items[id].secondaryQuantity - 1,
          },
        },
      };
    }
    case "DECREASE_ITEM_SIDE_QUANTITY": {
      const { item, side } = action.payload;
      if (!state.items[item]) {
        return state;
      }
      if (!state.items[item].sides?.[side]?.quantity) {
        return state;
      }
      if (state.items[item].sides[side].quantity === 1) {
        if (state.items[item].sides.length === 1) {
          const i = { ...state.items[item] };
          delete i.sides;
          return {
            ...state,
            items: {
              ...state.items,
              [item]: i,
            },
          };
        }
        const s = { ...state.items[item].sides };
        delete s[side];
        return {
          ...state,
          items: {
            ...state.items,
            [item]: {
              ...state.items[item],
              sides: s,
            },
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [item]: {
            ...state.items[item],
            sides: {
              ...state.items[item].sides,
              [side]: {
                ...state.items[item].sides[side],
                quantity: state.items[item].sides[side].quantity - 1,
              },
            },
          },
        },
      };
    }
    case "INCREASE_ITEM_QUANTITY": {
      const { id } = action.payload;
      if (state.items[id] === undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [id]: {
              quantity: 1,
            },
          },
        };
      }
      if (state.items[id].quantity === undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [id]: {
              ...state.items[id],
              quantity: 1,
            },
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            quantity: state.items[id].quantity + 1,
          },
        },
      };
    }
    case "INCREASE_ITEM_SECONDARY_QUANTITY": {
      const { id } = action.payload;
      if (state.items[id] === undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [id]: {
              secondaryQuantity: 1,
            },
          },
        };
      }
      if (state.items[id].secondaryQuantity === undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [id]: {
              ...state.items[id],
              secondaryQuantity: 1,
            },
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            secondaryQuantity: state.items[id].secondaryQuantity + 1,
          },
        },
      };
    }
    case "INCREASE_ITEM_SIDE_QUANTITY": {
      const { item, side } = action.payload;
      if (state.items[item] === undefined) {
        return state;
      }
      if (
        !!state.items[item].quantity &&
        state.items[item].sides === undefined
      ) {
        return {
          ...state,
          items: {
            ...state.items,
            [item]: {
              ...state.items[item],
              sides: {
                [side]: {
                  quantity: 1,
                },
              },
            },
          },
        };
      }
      if (
        state.items[item].quantity ===
        Object.keys(state.items[item].sides).reduce(
          (acc, cur) => acc + state.items[item].sides[cur].quantity,
          0
        )
      ) {
        return state;
      }
      if (state.items[item].sides[side] === undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [item]: {
              ...state.items[item],
              sides: {
                ...state.items[item].sides,
                [side]: {
                  quantity: 1,
                },
              },
            },
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [item]: {
            ...state.items[item],
            sides: {
              ...state.items[item].sides,
              [side]: {
                ...state.items[item].sides[side],
                quantity: state.items[item].sides[side].quantity + 1,
              },
            },
          },
        },
      };
    }
    case "REHYDRATE":
      if (action.payload === null) {
        return { items: {} };
      }
      return action.payload;
    case "RESET_CART":
      return { items: {} };
    case "SET_ITEM_QUANTITY": {
      const { id, quantity } = action.payload;
      if (typeof quantity !== "number") {
        throw new Error(
          `invalid desired item quantity type: ${quantity} for id: ${id}`
        );
      }
      if (isNaN(quantity)) {
        throw new Error(
          `invalid desired item quantity type: ${quantity} for id: ${id}`
        );
      }
      if (quantity < 0) {
        throw new Error(
          `invalid desired item quantity: ${quantity} for id: ${id}`
        );
      }
      if (quantity === 0) {
        if (state.items[id].secondaryQuantity === undefined) {
          const items = { ...state.items };
          delete items[id];
          return {
            ...state,
            items,
          };
        }
        const item = { ...state.items[id] };
        delete item.quantity;
        return {
          ...state,
          items: {
            ...state.items,
            [id]: item,
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            quantity,
          },
        },
      };
    }
    case "SET_ITEM_SECONDARY_QUANTITY": {
      const { id, secondaryQuantity } = action.payload;
      if (typeof secondaryQuantity !== "number") {
        throw new Error(
          `invalid desired item secondaryQuantity type: ${secondaryQuantity} for id: ${id}`
        );
      }
      if (isNaN(secondaryQuantity)) {
        throw new Error(
          `invalid desired item secondaryQuantity type: ${secondaryQuantity} for id: ${id}`
        );
      }
      if (secondaryQuantity < 0) {
        throw new Error(
          `invalid desired item secondaryQuantity: ${secondaryQuantity} for id: ${id}`
        );
      }
      if (secondaryQuantity === 0) {
        if (state.items[id].quantity === undefined) {
          const items = { ...state.items };
          delete items[id];
          return {
            ...state,
            items,
          };
        }
        const item = { ...state.items[id] };
        delete item.secondaryQuantity;
        return {
          ...state,
          items: {
            ...state.items,
            [id]: item,
          },
        };
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            secondaryQuantity,
          },
        },
      };
    }
    case "SET_SPECIAL_REQUESTS": {
      const { id, specialRequests } = action.payload;
      if (!state.items[id]) {
        throw new Error(`invalid specialRequests for ${id}, ${id} not in cart`);
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            specialRequests,
          },
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useCartState() {
  const context = React.useContext(CartStateContext);
  if (context === undefined) {
    throw new Error("useCartState must be used within a CartProvider");
  }
  return context;
}

function useCartDispatch() {
  const context = React.useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error("useCartDispatch must be used within a CartProvider");
  }
  return context;
}
