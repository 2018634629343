export default function Warning({
  backgroundColor = "white",
  color = "black",
  size = 48,
}) {
  return (
    <svg
      fill={backgroundColor}
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
        fill={color}
      />
    </svg>
  );
}
