export default function useApple() {
  const { platform } = window.navigator;

  switch (platform) {
    case "iPhone":
    case "MacIntel":
    case "iPad":
    case "iPod":
    case "MacPPC":
    case "Mac68k":
      return true;
    default:
      return false;
  }
}
