import { useLayoutEffect, useState } from "react";

export default function useOverflowed(ref) {
  const [overflowed, setOverflowed] = useState(undefined);

  useLayoutEffect(() => {
    if (ref.current) {
      setOverflowed(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  return overflowed;
}
