import { dismissSnack, useSnacks } from "./SnacksContext.js";
import { useStyles } from "./StylesContext.js";
import Error from "./assets/Error.js";
import Warning from "./assets/Warning.js";
import Info from "./assets/Info.js";
import Success from "./assets/Success.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";
import hsb from "./lib/hsb.js";
import useViewport from "./lib/useViewport.js";

export default function SnacksQueue() {
  const { backgroundColorBody, colorBody } = useStyles();
  const [snacks, snacksDispatch] = useSnacks();
  const { width } = useViewport();

  if (snacks.length === 0) {
    return null;
  }
  return (
    <Div
      style={{
        // bottom: keyboardHeight === 0 ? 48.5 : 100,
        bottom: 0,
        justifyContent: "flex-end",
        position: "absolute",
        paddingLeft: 16,
        paddingRight: 16,
      }}
    >
      {snacks.map((message, index) => {
        if (!message.active) {
          return null;
        }
        return (
          <Div
            key={index}
            style={{
              alignItems: "center",
              backgroundColor: backgroundColorBody,
              boxShadow:
                "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)",
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              marginBottom: 16,
              paddingBottom: 8,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              width: width - 32,
            }}
          >
            {message.type === "info" ? (
              <Info color={hsb(240, 90, 6 / 8)} />
            ) : message.type === "success" ? (
              <Success color={hsb(120, 90, 6 / 8)} />
            ) : message.type === "warning" ? (
              <Warning color={hsb(60, 90, 6 / 8)} />
            ) : (
              <Error color={hsb(0, 90, 6 / 8)} />
            )}
            <Div
              style={{
                KhtmlUserSelect: "none",
                MozUserSelect: "none",
                MsUserSelect: "none",
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                color: colorBody,
                fontSize: 16,
                paddingLeft: 16,
                paddingRight: 16,
                userSelect: "none",
                whiteSpace: "pre-line",
              }}
            >
              {message.body}
            </Div>
            <Pressable
              innerStyle={{ paddingBottom: 2 }}
              onPress={() => dismissSnack({ dispatch: snacksDispatch, index })}
              outerStyle={{ padding: 8 }}
            >
              &#x2715;
            </Pressable>
          </Div>
        );
      })}
    </Div>
  );
}
