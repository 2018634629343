import { createContext, useContext, useEffect, useReducer } from "react";

const OrdersStateContext = createContext();
const OrdersDispatchContext = createContext();

const initialState = {
  deliveryAddress: "",
  deliveryAddress2: "",
  deliveryCity: "",
  deliveryState: "",
  deliveryZip: "",
  name: "",
  phoneNumber: "",

  // orders: [], // part of initialState but need to declare separately (objects are copied by reference)
};

export function OrdersProvider({ children }) {
  const [state, dispatch] = useReducer(persistingReducer, {
    ...initialState,
    orders: [],
  });

  useEffect(_rehydrate, []);

  return (
    <OrdersStateContext.Provider value={state}>
      <OrdersDispatchContext.Provider value={dispatch}>
        {children}
      </OrdersDispatchContext.Provider>
    </OrdersStateContext.Provider>
  );

  function _rehydrate() {
    const payload = JSON.parse(localStorage.getItem("orders"));
    payload?.orders?.forEach?.((order) => {
      order.timePlaced = new Date(order.timePlaced);
      order.timeReady = new Date(order.timeReady);
      order.transactionTime = new Date(order.transactionTime);
    });

    dispatch({
      // https://stackoverflow.com/a/1353711
      payload,
      type: "REHYDRATE",
    });
  }
}

export function saveOrder({
  dispatch,
  deliveryAddress,
  deliveryAddress2,
  deliveryCity,
  deliveryState,
  deliveryZip,
  id,
  items,
  name,
  paid,
  phoneNumber,
  specialRequests,
  timePlaced,
  timeReady,
  total,
  transaction,
  transactionTime,
}) {
  dispatch({
    payload: {
      deliveryAddress,
      deliveryAddress2,
      deliveryCity,
      deliveryState,
      deliveryZip,
      id,
      items,
      name,
      paid,
      phoneNumber,
      specialRequests,
      timePlaced,
      timeReady,
      total,
      transaction,
      transactionTime,
    },
    type: "SAVE_ORDER",
  });
}

export function useOrders() {
  return [useOrdersState(), useOrdersDispatch()];
}

function persistingReducer(state, action) {
  const newState = reducer(state, action);
  localStorage.setItem("orders", JSON.stringify(newState));
  return newState;
}

function reducer(state, action) {
  switch (action.type) {
    case "REHYDRATE":
      if (action.payload === null) {
        return { ...initialState, orders: [] };
      }
      return action.payload;
    case "SAVE_ORDER":
      const delivery = !!action.payload.deliveryAddress;
      return {
        // ...state,
        orders: [
          ...state.orders,
          {
            deliveryAddress: action.payload.deliveryAddress,
            deliveryAddress2: action.payload.deliveryAddress2,
            deliveryCity: action.payload.deliveryCity,
            deliveryState: action.payload.deliveryState,
            deliveryZip: action.payload.deliveryZip,
            id: action.payload.id,
            items: action.payload.items,
            name: action.payload.name,
            paid: action.payload.paid,
            phoneNumber: action.payload.phoneNumber,
            specialRequests: action.payload.specialRequests,
            timePlaced: action.payload.timePlaced,
            timeReady: action.payload.timeReady,
            total: action.payload.total,
            transaction: action.payload.transaction,
            transactionTime: action.payload.transactionTime,
          },
        ],
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useOrdersState() {
  const context = useContext(OrdersStateContext);
  if (context === undefined) {
    throw new Error("useOrdersState must be used within a OrdersProvider");
  }
  return context;
}

function useOrdersDispatch() {
  const context = useContext(OrdersDispatchContext);
  if (context === undefined) {
    throw new Error("useOrdersDispatch must be used within a OrdersProvider");
  }
  return context;
}
