import { useHistory, useLocation } from "react-router-dom";

import { useCart } from "./CartContext.js";
import { useMenu } from "./MenuContext.js";
import { useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";

export default function NavBar({ clearQuery, queryLength }) {
  const [cart] = useCart();
  const { downloadIfStale } = useMenu();
  const {
    backgroundColorHeader,
    backgroundColorHeaderAlternate,
    colorHeader,
    colorHeaderAlternate,
  } = useStyles();
  const history = useHistory();
  const location = useLocation();

  return (
    <Div
      style={{
        boxShadow:
          "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
        backgroundColor: backgroundColorHeader,
        flexBasis: "auto",
        flexDirection: "row",
        flexGrow: 0,
        justifyContent: "space-around",
        zIndex: 4,
      }}
    >
      <Pressable
        disabled={location.pathname === "/menu" && queryLength === 0}
        innerStyle={{
          color:
            location.pathname === "/menu" && queryLength === 0
              ? colorHeaderAlternate
              : colorHeader,
        }}
        onDisabledPress={downloadIfStale}
        onPress={() => {
          downloadIfStale();
          clearQuery();
          history.push("/menu");
        }}
        outerStyle={{
          backgroundColor:
            location.pathname === "/menu" && queryLength === 0
              ? backgroundColorHeaderAlternate
              : backgroundColorHeader,
          flexBasis: 0,
          flexGrow: 1,
          padding: 16,
          textDecoration:
            location.pathname === "/menu" && queryLength === 0
              ? "none"
              : "underline",
        }}
      >
        Menu
      </Pressable>
      <Pressable
        disabled={location.pathname === "/cart"}
        innerStyle={{
          color:
            location.pathname === "/cart" ? colorHeaderAlternate : colorHeader,
        }}
        onPress={() => history.push("/cart")}
        outerStyle={{
          backgroundColor:
            location.pathname === "/cart"
              ? backgroundColorHeaderAlternate
              : backgroundColorHeader,
          flexBasis: 0,
          flexGrow: 1,
          padding: 16,
          textDecoration: location.pathname === "/cart" ? "none" : "underline",
        }}
      >
        Cart
        {!!Object.keys(cart.items).length &&
          ` (${Object.keys(cart.items).reduce(
            (acc, cur) =>
              acc +
              (cart.items[cur].quantity ?? 0) +
              (cart.items[cur].secondaryQuantity ?? 0),
            0
          )})`}
      </Pressable>
      <Pressable
        disabled={location.pathname === "/orders"}
        innerStyle={{
          color:
            location.pathname === "/orders"
              ? colorHeaderAlternate
              : colorHeader,
        }}
        onPress={() => history.push("/orders")}
        outerStyle={{
          backgroundColor:
            location.pathname === "/orders"
              ? backgroundColorHeaderAlternate
              : backgroundColorHeader,
          flexBasis: 0,
          flexGrow: 1,
          padding: 16,
          textDecoration:
            location.pathname === "/orders" ? "none" : "underline",
        }}
      >
        Orders
      </Pressable>
      <Pressable
        disabled={location.pathname === "/about"}
        innerStyle={{
          color:
            location.pathname === "/about" ? colorHeaderAlternate : colorHeader,
        }}
        onPress={() => history.push("/about")}
        outerStyle={{
          backgroundColor:
            location.pathname === "/about"
              ? backgroundColorHeaderAlternate
              : backgroundColorHeader,
          flexBasis: 0,
          flexGrow: 1,
          padding: 16,
          textDecoration: location.pathname === "/about" ? "none" : "underline",
        }}
      >
        About
      </Pressable>
    </Div>
  );
}
