import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import About from "./About.js";
import Cart from "./Cart.js";
import { CartProvider } from "./CartContext.js";
import Checkout from "./Checkout.js";
import Fallback from "./Fallback.js";
import { HoursProvider } from "./HoursContext.js";
import Menu from "./Menu.js";
import { MenuProvider } from "./MenuContext.js";
import NavBar from "./NavBar.js";
import Order from "./Order.js";
import Orders from "./Orders.js";
import { OrdersProvider } from "./OrdersContext.js";
import { SnacksProvider } from "./SnacksContext.js";
import SnacksQueue from "./SnacksQueue.js";
import { StylesProvider, useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";
import useViewport from "./lib/useViewport.js";

export default function ContextProviderWrapper() {
  return (
    <CartProvider>
      <HoursProvider>
        <MenuProvider>
          <OrdersProvider>
            <SnacksProvider>
              <StylesProvider>
                <App />
              </StylesProvider>
            </SnacksProvider>
          </OrdersProvider>
        </MenuProvider>
      </HoursProvider>
    </CartProvider>
  );
}

function App() {
  const {
    backgroundColorBody: backgroundColor,
    colorBody: color,
  } = useStyles();
  const { height, width } = useViewport();
  const [query, setQuery] = useState("");
  return (
    <Div style={{ backgroundColor, color, height, margin: "0 auto", width }}>
      <Router>
        <NavBar clearQuery={() => setQuery("")} queryLength={query.length} />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/checkout">
            <Checkout />
          </Route>
          <Route path="/menu">
            <Menu query={query} setQuery={setQuery} />
          </Route>
          <Route path="/order/:id">
            <Order />
          </Route>
          <Route path="/orders">
            <Orders />
          </Route>
          <Route path="/">
            <Fallback />
          </Route>
        </Switch>
      </Router>
      <SnacksQueue />
    </Div>
  );
}
