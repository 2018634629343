import { useEffect, useRef, useState } from "react";

import DishSidesModal from "./DishSidesModal.js";
import {
  decreaseItemQuantity,
  decreaseItemSecondaryQuantity,
  increaseItemQuantity,
  increaseItemSecondaryQuantity,
  setSpecialRequests,
  useCart,
} from "./CartContext.js";
import { useMenu } from "./MenuContext.js";
import { useStyles } from "./StylesContext.js";
import Chili from "./assets/Chili.js";
import Done from "./assets/Done.js";
import Edit from "./assets/Edit.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";
import useDebounce from "./lib/useDebounce.js";
import useOverflowed from "./lib/useOverflowed.js";

export default function SectionDishRow({
  id,
  inCart = false,
  showSection = false,
}) {
  const [cart, cartDispatch] = useCart();
  const {
    backgroundColorButton,
    backgroundColorButtonCTA,
    colorButton,
    colorButtonCTA,
    colorSecondary,
  } = useStyles();
  const { dishes, sections, sides } = useMenu();
  const hasSides = sections[dishes[id].section].addID !== undefined;
  const [editMode, setEditMode] = useState(!inCart);
  const {
    name,
    nameChinese,
    price,
    section,
    spicy,
    unit,
    unitChinese,

    secondaryPrice,
    secondaryUnit,
    secondaryUnitChinese,
  } = dishes[id];
  const [missingSides, setMissingSides] = useState(0);
  const nameRef = useRef(null);
  const [normalCTA, setNormalCTA] = useState(true);
  const noteRef = useRef(null);
  const sectionRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [sidesPrice, setSidesPrice] = useState(0);

  const debouncedMissingSides = useDebounce(missingSides, 500);
  const nameOverflowed = useOverflowed(nameRef);
  const noteOverflowed = useOverflowed(noteRef);
  const sectionOverflowed = useOverflowed(sectionRef);

  useEffect(() => {
    if (!hasSides) {
      return;
    }
    if (!cart.items[id]?.quantity) {
      setMissingSides(0);
      return;
    }
    setMissingSides(
      (cart.items[id]?.quantity ?? 0) -
        (Object.keys(cart.items[id]?.sides ?? [])?.reduce?.(
          (acc, cur) => acc + cart.items[id]?.sides?.[cur]?.quantity,
          0
        ) ?? 0)
    );
  }, [cart.items[id], hasSides]);

  useEffect(() => {
    if (debouncedMissingSides) {
      setShowModal(true);
    }
  }, [debouncedMissingSides]);

  useEffect(() => {
    if (!hasSides) {
      setNormalCTA(true);
      return;
    }
    if (cart.items[id]?.quantity === undefined) {
      setNormalCTA(true);
      return;
    }
    setSidesPrice(
      Object.keys(cart.items[id].sides ?? {}).reduce(
        (acc, side) =>
          acc + (sides[side].price ?? 0) * cart.items[id].sides[side].quantity,
        0
      ) ?? 0
    );
    if (
      cart.items[id].sides === undefined ||
      sidesQuantity(cart.items[id]?.sides) !== (cart.items[id]?.quantity ?? 0)
    ) {
      setNormalCTA(false);
      return;
    }
    setNormalCTA(true);
  }, [cart.items, hasSides, id, sides]);

  return (
    <Div
      style={{
        flexBasis: "auto",
        flexGrow: 0,
        marginBottom: 16,
        marginTop: 16,
        padding: 16,
      }}
    >
      {showSection && (
        <Div
          style={{
            flexBasis: "auto",
            flexGrow: 0,
            paddingBottom: 16,
          }}
        >
          <h3
            ref={sectionRef}
            style={{
              marginTop: -4,
              overflowX: !!sectionOverflowed ? "auto" : "hidden",
            }}
          >
            {sections[section].name}{" "}
            <span style={{ color: colorSecondary }}>
              ({sections[section].nameChinese})
            </span>
          </h3>
          {!!sections[section].note && (
            <p
              ref={noteRef}
              style={{
                color: colorSecondary,
                marginBottom: -8,
                marginLeft: 16,
                marginTop: -16,
                overflowX: !!noteOverflowed ? "auto" : "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {sections[section].note}
            </p>
          )}
        </Div>
      )}
      <Div
        style={{
          alignItems: "center",
          flexBasis: "auto",
          flexDirection: "row",
          flexGrow: 0,
        }}
      >
        {inCart && (
          <Div
            style={{
              color: colorSecondary,
              flexBasis: "auto",
              flexGrow: 0,
              height: "100%",
              justifyContent: "space-around",
              width: 48,
            }}
          >
            {cart.items[id].quantity !== undefined && (
              <span>
                {cart.items[id].quantity} {dishes[id].unit}
              </span>
            )}
            {cart.items[id].secondaryQuantity !== undefined && (
              <span>
                {cart.items[id].secondaryQuantity} {dishes[id].secondaryUnit}
              </span>
            )}
          </Div>
        )}
        <Div>
          {dishes[id].status !== 1 && (
            <Div
              style={{
                color: "red",
                flexBasis: "auto",
                flexGrow: 0,
                // flexDirection: "row",
                fontSize: 36,
              }}
            >
              {dishes[id].status === 2 ? "Out of stock" : "Discontinued"}
            </Div>
          )}
          <Div
            style={{
              alignItems: "center",
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              paddingRight: 16,
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: inCart ? 0 : 16,
            }}
          >
            {!!dishes[id].picture && (
              <img
                height={40}
                src={
                  process.env.NODE_ENV === "production"
                    ? `/pictures/${dishes[id].picture}`
                    : `http://${process.env.REACT_APP_apiBase}:8080/pictures/${dishes[id].picture}`
                }
                style={{ marginRight: 16 }}
              />
            )}
            <Div>
              <Div
                ref={nameRef}
                style={{
                  alignItems: "center",
                  flexBasis: "auto",
                  flexGrow: 0,
                  flexDirection: "row",
                  overflowX: !!nameOverflowed ? "auto" : "hidden",
                  paddingBottom: 8,
                }}
              >
                {spicy && (
                  <span style={{ marginRight: 8, marginTop: 2 }}>
                    <Chili size={16} />
                  </span>
                )}
                <Div
                  style={{
                    flexBasis: "auto",
                    flexGrow: 0,
                    fontSize: 24,
                    textDecoration:
                      dishes[id].status === 1 ? "none" : "line-through",
                    whiteSpace: "nowrap",
                  }}
                >
                  {name}
                </Div>
              </Div>
              <Div
                style={{
                  color: colorSecondary,
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  justifyContent: "space-between",
                }}
              >
                <span>{nameChinese}</span>
                <span>
                  {inCart &&
                  (cart.items[id].quantity !== undefined ||
                    cart.items[id].secondaryQuantity !== undefined) ? (
                    <span>
                      $
                      {Math.floor(
                        ((cart.items[id].quantity ?? 0) * price +
                          (cart.items[id].secondaryQuantity ?? 0) *
                            (secondaryPrice ?? 0)) /
                          100
                      )}
                      .
                      {((cart.items[id].quantity ?? 0) * price +
                        (cart.items[id].secondaryQuantity ?? 0) *
                          (secondaryPrice ?? 0)) %
                        100 <
                      10
                        ? `0${
                            ((cart.items[id].quantity ?? 0) * price +
                              (cart.items[id].secondaryQuantity ?? 0) *
                                (secondaryPrice ?? 0)) %
                            100
                          }`
                        : ((cart.items[id].quantity ?? 0) * price +
                            (cart.items[id].secondaryQuantity ?? 0) *
                              (secondaryPrice ?? 0)) %
                          100}
                    </span>
                  ) : (
                    <>
                      {!inCart && (
                        <span>
                          ${Math.floor(price / 100)}.
                          {price % 100 < 10 ? `0${price % 100}` : price % 100}
                        </span>
                      )}
                      {!inCart && secondaryUnit !== undefined && (
                        <span>&nbsp;/&nbsp;</span>
                      )}
                      {!inCart && secondaryUnit !== undefined && (
                        <span>
                          ${Math.floor(secondaryPrice / 100)}.
                          {secondaryPrice % 100 < 10
                            ? `0${secondaryPrice % 100}`
                            : secondaryPrice % 100}
                        </span>
                      )}
                    </>
                  )}
                </span>
              </Div>
              {inCart && sidesPrice !== 0 && (
                <Div
                  style={{
                    color: colorSecondary,
                    flexBasis: "auto",
                    flexGrow: 0,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 8,
                  }}
                >
                  <span>Sides (total)</span>
                  <span>
                    ${Math.floor(sidesPrice / 100)}.
                    {sidesPrice % 100 < 10
                      ? `0${sidesPrice % 100}`
                      : sidesPrice % 100}
                  </span>
                </Div>
              )}
            </Div>
          </Div>
        </Div>
        {inCart && (
          <Div
            style={{
              alignItems: "center",
              flexBasis: "auto",
              flexGrow: 0,
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Pressable
              onPress={() => setEditMode(!editMode)}
              outerStyle={{
                backgroundColor: backgroundColorButton,
                marginBottom: 4,
                marginLeft: 16,
                marginRight: 4,
                marginTop: 4,
                padding: 8,
              }}
            >
              {editMode ? (
                <Done color={colorButton} size={24} />
              ) : (
                <Edit color={colorButton} size={24} />
              )}
            </Pressable>
          </Div>
        )}
      </Div>
      {(editMode || !inCart) && (
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-around",
            marginTop: 8,
          }}
        >
          <Div
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            {!!cart.items?.[id]?.quantity ? (
              <Pressable
                innerStyle={{ color: colorButton }}
                onPress={() => {
                  decreaseItemQuantity({ dispatch: cartDispatch, id });
                }}
                outerStyle={{
                  backgroundColor: backgroundColorButton,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                }}
              >
                -
              </Pressable>
            ) : (
              <Div
                style={{
                  alignItems: "center",
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  justifyContent: "center",
                  padding: 8,
                }}
              ></Div>
            )}
          </Div>
          <Div
            style={{
              alignItems: "center",
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              justifyContent: "center",
              marginTop: 8,
              maxWidth: 80,
              minWidth: 80,
              width: 80,
            }}
          >
            {cart.items?.[id]?.quantity}
            {secondaryUnit === undefined ? "" : ` ${unit} (${unitChinese})`}
          </Div>
          <Div
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <Pressable
              innerStyle={{
                color: !inCart && normalCTA ? colorButtonCTA : colorButton,
              }}
              onPress={() => {
                increaseItemQuantity({ dispatch: cartDispatch, id });
              }}
              outerStyle={{
                backgroundColor:
                  !inCart && normalCTA
                    ? backgroundColorButtonCTA
                    : backgroundColorButton,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
            >
              +
            </Pressable>
          </Div>
        </Div>
      )}
      {(editMode || !inCart) && secondaryUnit !== undefined && (
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-around",
          }}
        >
          <Div
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            {!!cart.items?.[id]?.secondaryQuantity ? (
              <Pressable
                innerStyle={{ color: colorButton }}
                onPress={() => {
                  decreaseItemSecondaryQuantity({ dispatch: cartDispatch, id });
                }}
                outerStyle={{
                  backgroundColor: backgroundColorButton,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                }}
              >
                -
              </Pressable>
            ) : (
              <Div
                style={{
                  alignItems: "center",
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  justifyContent: "center",
                  padding: 8,
                }}
              ></Div>
            )}
          </Div>
          <Div
            style={{
              alignItems: "center",
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              justifyContent: "center",
              marginTop: 8,
              maxWidth: 80,
              minWidth: 80,
              width: 80,
            }}
          >
            {cart.items?.[id]?.secondaryQuantity} {secondaryUnit} (
            {secondaryUnitChinese})
          </Div>
          <Div
            style={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <Pressable
              innerStyle={{
                color: !inCart && normalCTA ? colorButtonCTA : colorButton,
              }}
              onPress={() => {
                increaseItemSecondaryQuantity({ dispatch: cartDispatch, id });
              }}
              outerStyle={{
                backgroundColor:
                  !inCart && normalCTA
                    ? backgroundColorButtonCTA
                    : backgroundColorButton,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
            >
              +
            </Pressable>
          </Div>
        </Div>
      )}
      {!hasSides ? null : cart.items[id]?.quantity ===
        undefined ? null : !editMode && inCart && normalCTA ? null : (
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-around",
            marginTop: 16,
          }}
        >
          <Pressable
            innerStyle={{ color: normalCTA ? colorButton : colorButtonCTA }}
            onPress={() => setShowModal(true)}
            outerStyle={{
              backgroundColor: normalCTA
                ? backgroundColorButton
                : backgroundColorButtonCTA,
              marginTop: 8,
              paddingBottom: 8,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
            }}
            pressScale={1.1}
          >
            {sidesQuantity(cart.items[id]?.sides) <
            (cart.items[id]?.quantity ?? 0)
              ? `Pick ${
                  cart.items[id].quantity - sidesQuantity(cart.items[id].sides)
                } ${
                  sidesQuantity(cart.items[id]?.sides) === 0 ? "" : "more "
                }side${
                  cart.items[id].quantity -
                    sidesQuantity(cart.items[id].sides) ===
                  1
                    ? ""
                    : "s"
                }`
              : sidesQuantity(cart.items[id]?.sides) ===
                (cart.items[id]?.quantity ?? 0)
              ? "Change sides"
              : `Remove ${
                  sidesQuantity(cart.items[id].sides) - cart.items[id].quantity
                } side${
                  sidesQuantity(cart.items[id].sides) -
                    cart.items[id].quantity ===
                  1
                    ? ""
                    : "s"
                }`}
          </Pressable>
        </Div>
      )}
      {cart.items[id]?.specialRequests !== undefined && (
        <input
          disabled={inCart && !editMode}
          onChange={(event) =>
            setSpecialRequests({
              dispatch: cartDispatch,
              id,
              specialRequests: event.target.value,
            })
          }
          placeholder="Special request[s]"
          style={{
            marginTop: 16,
            paddingBottom: 8,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 8,
          }}
          value={cart.items[id]?.specialRequests}
        />
      )}
      {(!inCart || editMode) && cart.items?.[id] && (
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-around",
            marginTop: 16,
          }}
        >
          <Div
            style={{
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Div />
            {cart.items[id]?.specialRequests !== undefined ? (
              <Pressable
                innerStyle={{ color: colorButton }}
                onPress={() =>
                  setSpecialRequests({
                    dispatch: cartDispatch,
                    id,
                    specialRequests: undefined,
                  })
                }
                outerStyle={{
                  backgroundColor: backgroundColorButton,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                }}
                pressScale={1.1}
              >
                Remove special request[s]
              </Pressable>
            ) : (
              <Pressable
                innerStyle={{ color: colorButton }}
                onPress={() =>
                  setSpecialRequests({
                    dispatch: cartDispatch,
                    id,
                    specialRequests: "",
                  })
                }
                outerStyle={{
                  backgroundColor: backgroundColorButton,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                }}
                pressScale={1.1}
              >
                Make special request[s]
              </Pressable>
            )}
            <Div />
          </Div>
        </Div>
      )}
      {showModal ? <DishSidesModal hide={_hideModal} id={id} /> : null}
    </Div>
  );

  function _hideModal() {
    setShowModal(false);
  }
}

function sidesQuantity(sides) {
  if (sides === undefined) {
    return 0;
  }
  return Object.keys(sides).reduce((acc, cur) => acc + sides[cur].quantity, 0);
}
