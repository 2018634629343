import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useMenu } from "./MenuContext.js";
import SectionDishRow from "./SectionDishRow.js";
import { useStyles } from "./StylesContext.js";
import Clear from "./assets/Clear.js";
import Search from "./assets/Search.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";
import useDebounce from "./lib/useDebounce.js";
import useViewport from "./lib/useViewport.js";

export default function Section() {
  const { backgroundColorButton, colorButton, colorSecondary } = useStyles();
  const [candidates, setCandidates] = useState([]);
  const { dishes, dishTrigrams, sections } = useMenu();
  const { id: section } = useParams();
  const [query, setQuery] = useState("");
  const searchInputNode = useRef(null);
  const { width } = useViewport();

  const debouncedQuery = useDebounce(query, 250);
  const { name, nameChinese, note } = sections[section];
  const options = [...Object.keys(dishes)]
    .filter((id) => dishes[id].section === section)
    .map((id) => ({
      id,
      name: sections[id]
        ? sections[id]?.name.toLowerCase().replace(/[^a-z]/g, "")
        : dishes[id]?.name.toLowerCase().replace(/[^a-z]/g, ""),
    }));

  useEffect(() => {
    if (debouncedQuery) {
      _fuzzyMatch({ query });
    }
  }, [debouncedQuery]);

  return (
    <Div>
      <div
        style={{
          boxShadow:
            "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)",
        }}
      >
        <h2 style={{ marginLeft: 16 }}>
          {name} <span style={{ color: colorSecondary }}>({nameChinese})</span>
        </h2>
        {!!note && (
          <p style={{ color: colorSecondary, marginLeft: 32, marginTop: -16 }}>
            {note}
          </p>
        )}
      </div>
      {query ? (
        <Div
          style={{
            alignItems: "center",
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            padding: 16,
          }}
        >
          {!!sections[section].picture && (
            <img
              height={40}
              src={
                process.env.NODE_ENV === "production"
                  ? `/pictures/${sections[section].picture}`
                  : `http://${process.env.REACT_APP_apiBase}:8080/pictures/${sections[section].picture}`
              }
              style={{ marginRight: 16 }}
            />
          )}
          <Div
            style={{
              overflowY: "auto",
            }}
          >
            {query.length < 3 ? (
              <Div>
                Please enter at least 3 letters to search{" "}
                {sections[section].name} dishes.
              </Div>
            ) : candidates.length === 0 ? (
              <Div>No results. Please try a different search.</Div>
            ) : (
              candidates
                .filter(([id]) => dishes[id]?.status === 1)
                .map(([id]) => <SectionDishRow id={dishes[id].id} key={id} />)
            )}
          </Div>
        </Div>
      ) : (
        <Div style={{ overflowY: "auto" }}>
          {Object.keys(dishes)
            .filter((dish) => dishes[dish]?.section === section)
            .filter((dish) => dishes[dish]?.status === 1)
            .map((dish) => (
              <SectionDishRow id={dish} key={dish} />
            ))}
        </Div>
      )}
      <Div
        style={{
          alignItems: "center",
          backgroundColor: backgroundColorButton,
          flexBasis: "auto",
          flexDirection: "row",
          flexGrow: 0,
        }}
      >
        <input
          onChange={(event) => setQuery(event.target.value)}
          placeholder={`Search for ${name} dish`}
          ref={searchInputNode}
          style={{
            display: "flex",
            flexBasis: 0,
            flexGrow: 1,
            fontSize: 24,
            paddingBottom: 12,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 12,
            width: width - 88,
          }}
          type="text"
          value={query}
        />
        <Pressable
          onPress={() => {
            if (query) {
              setCandidates([]);
              setQuery("");
            }
            searchInputNode.current.focus();
          }}
          outerStyle={{
            backgroundColor: backgroundColorButton,
            padding: 16,
          }}
        >
          {query ? (
            <Clear
              backgroundColor={backgroundColorButton}
              color={colorButton}
              size={24}
            />
          ) : (
            <Search
              backgroundColor={backgroundColorButton}
              color={colorButton}
              size={24}
            />
          )}
        </Pressable>
      </Div>
    </Div>
  );

  function _fuzzyMatch({ query }) {
    const formattedQuery = query.toLowerCase().replace(/[^a-z ]/g, "");
    if (formattedQuery.length < 3) {
      return;
      setCandidates(
        options.filter((option) =>
          option.name.toLowerCase().includes(formattedQuery)
        )
        // options.filter((option) => {
        //   let j = -1;
        //   for (let i = 0; i < formattedQuery.length; i++) {
        //     let c = formattedQuery[i];
        //     j = option.name.indexOf(c, j + 1);
        //     if (j == -1) {
        //       return false;
        //     }
        //   }
        //   return true;
        // })
      );
      return;
    }
    let uniqueTrigrams = {};
    const terms = formattedQuery.split(" ");
    for (let i = 0, tlen = terms.length; i < tlen; i++) {
      let trigrams = _trigrams({ term: terms[i] });
      for (const trigram in trigrams) {
        if (uniqueTrigrams[trigram] !== undefined) {
          uniqueTrigrams[trigram]++;
          continue;
        }
        uniqueTrigrams[trigram] = 1;
      }
    }
    let uniqueCandidates = {};
    for (const trigram in uniqueTrigrams) {
      const occurrences = uniqueTrigrams[trigram];
      let dt = dishTrigrams[trigram];
      if (dt === undefined) {
        continue;
      }
      for (
        let j = 0, dtLen = dt.length, candidate = dt[0];
        j < dtLen;
        candidate = dt[++j]
      ) {
        if (uniqueCandidates[candidate] !== undefined) {
          uniqueCandidates[candidate] += occurrences;
          continue;
        }
        if (dishes[candidate].section !== section) {
          continue;
        }
        uniqueCandidates[candidate] = occurrences;
      }
    }
    setCandidates(
      Object.entries(uniqueCandidates).sort((a, b) => {
        if (a[1] < b[1]) {
          return 1;
        }
        if (a[1] > b[1]) {
          return -1;
        }
        return 0;
      })
    );
  }

  function _trigrams({ term }) {
    const len = term.length;
    const trigrams = {};
    for (let i = 0; i < len - 2; i++) {
      trigrams[term.slice(i, i + 3)] = true;
    }
    return trigrams;
  }
}
