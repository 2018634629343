import { useParams } from "react-router-dom";

import { useMenu } from "./MenuContext.js";
import { useOrders } from "./OrdersContext.js";
import { useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";

export default function Orders() {
  const [orders] = useOrders();
  const { backgroundColorBody, colorBody } = useStyles();
  const { id } = useParams();
  const order = orders.orders.find((o) => o.id === id);
  const { dishes, sides } = useMenu();
  let nowish = new Date();
  nowish = nowish.setHours(nowish.getHours() + 2);

  return (
    <Div
      style={{
        backgroundColor: backgroundColorBody,
        color: colorBody,
        overflowY: "auto",
      }}
    >
      <h1 style={{ paddingLeft: 16, paddingRight: 16 }}>Order: {id}</h1>
      <Detail label="Ready">{order?.timeReady.toLocaleString()}</Detail>
      <Detail label="Total">${order?.total}</Detail>
      {order?.timeReady > nowish && (
        <Detail label="Paid">{order?.paid ? "Yes" : "No"}</Detail>
      )}
      <Detail label="For">{order?.name}</Detail>
      <Detail label="Phone Number">
        ({order?.phoneNumber.substring(0, 3)}){" "}
        {order?.phoneNumber.substring(3, 6)} - {order?.phoneNumber.substring(6)}
      </Detail>
      {!!order?.deliveryAddress && (
        <Detail label="Delivery Address">
          {order?.deliveryAddress}
          <br />
          {!!order?.deliveryAddress2 && order?.deliveryAddress2 && <br />}
          {order?.deliveryCity}, {order?.deliveryState} {order?.deliveryZip}
        </Detail>
      )}
      <Detail label="Placed">{order?.timePlaced.toLocaleString()}</Detail>
      {!!order?.specialRequests && (
        <Detail label="Special Requests">{order?.specialRequests}</Detail>
      )}
      <Detail label="Items">
        {!!order?.items &&
          Object.keys(order?.items)?.map((dishID) => (
            <div key={dishID}>
              {!!order.items[dishID].quantity &&
              !!order.items[dishID].secondaryQuantity
                ? ` ${order.items[dishID].quantity} ${dishes[dishID].unit} & ${order.items[dishID].secondaryQuantity} ${dishes[dishID].secondaryUnit}`
                : !!order.items[dishID].secondaryQuantity
                ? `${order.items[dishID].secondaryQuantity} ${dishes[dishID].secondaryUnit}`
                : ` ${order.items[dishID].quantity} ${dishes[dishID].unit}`}
              &nbsp;
              {dishes[dishID]?.name}
              {!!order.items[dishID].specialRequests && (
                <div style={{ marginLeft: 16 }}>
                  {order.items[dishID].specialRequests}
                </div>
              )}
              {!!order.items[dishID]?.sides &&
                Object.keys(order.items[dishID].sides).map((sideID) => (
                  <div key={sideID} style={{ marginLeft: 16 }}>
                    {`${order.items[dishID].sides[sideID].quantity} with ${sides[sideID].name}`}
                  </div>
                ))}
            </div>
          ))}
      </Detail>
    </Div>
  );
}

function Detail({ children, label }) {
  const { colorBody, colorSecondary } = useStyles();
  return (
    <Div
      style={{
        color: colorBody,
        // display: "block",
        flexBasis: "auto",
        flexDirection: "row",
        flexGrow: 0,
        marginBottom: 16,
        marginLeft: 16,
        marginRight: 16,
      }}
    >
      {label}:&nbsp;
      <span
        style={{
          color: colorSecondary,
        }}
      >
        {children}
      </span>
    </Div>
  );
}

// orders: [
//   ...state.orders,
//   {
//     deliveryAddress: action.payload.deliveryAddress,
//     deliveryAddress2: action.payload.deliveryAddress2,
//     deliveryCity: action.payload.deliveryCity,
//     deliveryState: action.payload.deliveryState,
//     deliveryZip: action.payload.deliveryZip,
//     id: action.payload.id,
//     items: action.payload.items,
//     name: action.payload.name,
//     phoneNumber: action.payload.phoneNumber,
//     specialRequests: action.payload.specialRequests,
//     timePlaced: action.payload.timePlaced,
//     timeReady: action.payload.timeReady,
//     total: action.payload.total,
//     transaction: action.payload.transaction,
//     transactionTime: action.payload.transactionTime,
//   },
// ],
// name: action.payload.name,
// phoneNumber: action.payload.phoneNumber,
// ...(delivery && {
//   deliveryAddress: action.payload.deliveryAddress,
//   deliveryAddress2: action.payload.deliveryAddress2,
//   deliveryCity: action.payload.deliveryCity,
//   deliveryState: action.payload.deliveryState,
//   deliveryZip: action.payload.deliveryZip,
// }),
