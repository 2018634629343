import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { useCart } from "./CartContext.js";
import { useMenu } from "./MenuContext.js";
import SectionDishRow from "./SectionDishRow.js";
import { useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";

export default function Cart() {
  const {
    backgroundColorButtonCTA,
    backgroundColorButtonDisabled,
    backgroundColorFeature,
    colorButtonCTA,
    colorButtonDisabled,
    colorFeature,
  } = useStyles();
  const [cart] = useCart();
  const { dishes, downloadIfStale, sections, sides } = useMenu();
  const history = useHistory();
  const subtotal = Object.keys(cart.items).reduce(
    (acc, item) =>
      acc +
      (dishes[item].price ?? 0) * (cart.items[item].quantity ?? 0) +
      (dishes[item].secondaryPrice ?? 0) *
        (cart.items[item].secondaryQuantity ?? 0) +
      (Object.keys(cart.items[item].sides ?? {}).reduce(
        (acc, side) =>
          acc +
          (sides[side].price ?? 0) * cart.items[item].sides[side].quantity,
        0
      ) ?? 0),
    0
  );
  const taxRateString = process.env.REACT_APP_taxRate.split(".")[1];
  let tax = Math.floor(
    (subtotal * parseInt(taxRateString)) /
      Math.pow(10, taxRateString.length - 1)
  );
  if (tax % 10 >= 5) {
    tax += 10;
  }
  tax = Math.floor(tax / 10);
  const total = subtotal + tax;
  const subtotalString = `${Math.floor(subtotal / 100)}.${
    subtotal % 100 < 10 ? `0${subtotal % 100}` : subtotal % 100
  }`;
  const taxString = `${Math.floor(tax / 100)}.${
    tax % 100 < 10 ? `0${tax % 100}` : tax % 100
  }`;
  const totalString = `${Math.floor(total / 100)}.${
    total % 100 < 10 ? `0${total % 100}` : total % 100
  }`;
  const validCart =
    Object.keys(cart.items).length > 0 &&
    Object.keys(cart.items).every(
      (item) =>
        dishes[item].status === 1 &&
        (sections[dishes[item].section].addID === undefined ||
          (cart.items[item].sides !== undefined &&
            cart.items[item].quantity ===
              Object.keys(cart.items[item].sides).reduce(
                (acc, side) => acc + cart.items[item].sides[side].quantity,
                0
              )))
    );

  useEffect(downloadIfStale, []);

  return (
    <Div>
      <Div style={{ overflowY: "auto" }}>
        {Object.keys(cart.items).map((id) => (
          <SectionDishRow
            id={id}
            inCart={true}
            key={id}
            showSection={!!sections[dishes[id].section].hours}
          />
        ))}
      </Div>
      <Div
        style={{
          backgroundColor: backgroundColorFeature,
          color: colorFeature,
          flexBasis: "auto",
          flexGrow: 0,
          padding: 16,
        }}
      >
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-between",
            paddingBottom: 4,
            paddingTop: 4,
          }}
        >
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
            }}
          >
            Subtotal:
          </Div>
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
              justifyContent: "flex-end",
            }}
          >
            {subtotalString}
          </Div>
        </Div>
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-between",
            paddingBottom: 4,
            paddingTop: 4,
          }}
        >
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
            }}
          >
            Tax:
          </Div>
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
              justifyContent: "flex-end",
            }}
          >
            {taxString}
          </Div>
        </Div>
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            justifyContent: "space-between",
            paddingBottom: 4,
            paddingTop: 4,
          }}
        >
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
            }}
          >
            Total:
          </Div>
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
              justifyContent: "flex-end",
            }}
          >
            ${totalString}
          </Div>
        </Div>
      </Div>
      <Pressable
        disabled={!validCart}
        innerStyle={{
          color: validCart ? colorButtonCTA : colorButtonDisabled,
          fontSize: 24,
        }}
        onPress={_checkout}
        outerStyle={{
          alignItems: "center",
          backgroundColor: validCart
            ? backgroundColorButtonCTA
            : backgroundColorButtonDisabled,
          justifyContent: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          padding: 16,
        }}
      >
        Checkout
      </Pressable>
    </Div>
  );

  function _checkout() {
    history.push("/checkout");
  }
}
