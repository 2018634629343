import React, { createContext, useContext } from "react";

import hsb from "./lib/hsb.js";

const StylesContext = createContext();

// icon colors
// hsb(205, 97.3, 88.6) // darkest
// hsb(194, 96.5, 89.8) // mid
// hsb(188, 91.4, 95.3) // brightest
export function StylesProvider({ children }) {
  return (
    <StylesContext.Provider
      value={{
        backgroundColorBody: hsb(0, 0, 100),
        backgroundColorBodyAlternate: hsb(0, 0, 87.5),
        backgroundColorButton: hsb(0, 0, 75),
        backgroundColorButtonCTA: hsb(120, 100, 50),
        backgroundColorButtonDisabled: hsb(0, 0, 50),
        backgroundColorFeature: hsb(0, 0, 87.5),
        backgroundColorHeader: hsb(0, 0, 100),
        backgroundColorHeaderAlternate: hsb(0, 0, 100),
        backgroundColorSwitch: hsb(0, 0, 100),
        backgroundColorSwitchAlternate: hsb(0, 0, 75),
        backgroundColorSwitchDisabled: hsb(0, 0, 50),
        colorBody: hsb(0, 0, 10),
        colorBodyAlternate: hsb(0, 0, 10),
        colorButton: hsb(0, 0, 10),
        colorButtonCTA: hsb(0, 0, 100),
        colorButtonDisabled: hsb(0, 0, 10),
        colorFeature: hsb(0, 0, 10),
        colorHeader: hsb(0, 50, 10),
        colorHeaderAlternate: hsb(0, 0, 10),
        colorSecondary: hsb(0, 0, 55),
        colorSecondaryAlternate: hsb(0, 0, 55),
        colorSecondaryDisabled: hsb(0, 0, 10),
        colorSwitch: hsb(0, 0, 10),
        colorSwitchAlternate: hsb(0, 0, 10),
      }}
    >
      {children}
    </StylesContext.Provider>
  );
}

export function useStyles() {
  const context = useContext(StylesContext);
  if (context === undefined) {
    throw new Error("useStyles must be used within a StylesProvider");
  }
  return context;
}
