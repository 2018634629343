import { useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useMenu } from "./MenuContext.js";
import MenuSectionRow from "./MenuSectionRow.js";
import Section from "./Section.js";
import SectionDishRow from "./SectionDishRow.js";
import { useStyles } from "./StylesContext.js";
import Clear from "./assets/Clear.js";
import Search from "./assets/Search.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";
import useDebounce from "./lib/useDebounce.js";
import useViewport from "./lib/useViewport.js";

export default function Menu({ query, setQuery }) {
  const {
    backgroundColorBody,
    backgroundColorButton,
    colorBody,
    colorButton,
    colorSecondary,
  } = useStyles();
  const [candidates, setCandidates] = useState([]);
  const { dishes, dishTrigrams, sections, sectionTrigrams } = useMenu();
  const history = useHistory();
  const options = [...Object.keys(sections), ...Object.keys(dishes)].map(
    (id) => ({
      id,
      name: sections[id]
        ? sections[id]?.name.toLowerCase().replace(/[^a-z]/g, "")
        : dishes[id]?.name.toLowerCase().replace(/[^a-z]/g, ""),
    })
  );
  const searchInputNode = useRef(null);
  let { path } = useRouteMatch();
  const { width } = useViewport();

  const debouncedQuery = useDebounce(query, 250);

  useEffect(() => {
    if (debouncedQuery) {
      _fuzzyMatch({ query });
    }
  }, [debouncedQuery]);

  return (
    <Div style={{ overflowY: "auto" }}>
      <Switch>
        <Route exact path={path}>
          <Div>
            {query ? (
              <Div
                style={{
                  padding: 16,
                  overflowY: "auto",
                }}
              >
                {query.length < 3 ? (
                  <Div>Enter at least 3 letters to search the menu.</Div>
                ) : candidates.length === 0 ? (
                  <Div>No results. Please try a different search.</Div>
                ) : (
                  candidates
                    .filter(([id]) => !sections[id] && dishes[id]?.status === 1)
                    .map(([id]) =>
                      sections[id] ? (
                        <Pressable
                          id={id}
                          innerStyle={{
                            color: colorBody,
                            transformOrigin: "center left",
                            width: "100%",
                          }}
                          onPress={() => history.push(`${path}/section/${id}`)}
                          outerStyle={{ backgroundColor: backgroundColorBody }}
                          pressScale={1.2}
                        >
                          <Div style={{ padding: 16 }}>
                            <Div
                              style={{
                                alignItems: "center",
                                flexBasis: "auto",
                                flexDirection: "row",
                                flexGrow: 0,
                                fontSize: 24,
                              }}
                            >
                              {sections[id].name}&nbsp;
                              <span
                                style={{
                                  color: colorSecondary,
                                  fontSize: 16,
                                }}
                              >
                                ({sections[id].nameChinese})
                              </span>
                            </Div>
                            {!!sections[id].note && (
                              <Div
                                style={{
                                  color: colorSecondary,
                                  flexBasis: "auto",
                                  flexDirection: "row",
                                }}
                              >
                                {sections[id].note}
                              </Div>
                            )}
                          </Div>
                        </Pressable>
                      ) : (
                        <SectionDishRow id={id} key={id} showSection={true} />
                      )
                    )
                )}
              </Div>
            ) : (
              <Div style={{ overflowY: "auto" }}>
                {Object.keys(sections).map((id) => (
                  <MenuSectionRow
                    key={id}
                    id={id}
                    name={sections[id].name}
                    nameChinese={sections[id].nameChinese}
                    note={sections[id].note}
                  />
                ))}
              </Div>
            )}
            <Div
              style={{
                alignItems: "center",
                backgroundColor: backgroundColorButton,
                flexBasis: "auto",
                flexDirection: "row",
                flexGrow: 0,
              }}
            >
              <input
                onChange={(event) => setQuery(event.target.value)}
                onKeyPress={_blurOnEnter}
                placeholder="Search for section or dish"
                ref={searchInputNode}
                style={{
                  display: "flex",
                  flexBasis: 0,
                  flexGrow: 1,
                  fontSize: 24,
                  paddingBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  width: width - 88,
                }}
                type="text"
                value={query}
              />
              <Pressable
                onPress={() => {
                  if (query) {
                    setCandidates([]);
                    setQuery("");
                  }
                  searchInputNode.current.focus();
                }}
                outerStyle={{
                  backgroundColor: backgroundColorButton,
                  padding: 16,
                }}
              >
                {query ? (
                  <Clear
                    backgroundColor={backgroundColorButton}
                    color={colorButton}
                    size={24}
                  />
                ) : (
                  <Search
                    backgroundColor={backgroundColorButton}
                    color={colorButton}
                    size={24}
                  />
                )}
              </Pressable>
            </Div>
          </Div>
        </Route>
        <Route path={`${path}/section/:id`}>
          <Section />
        </Route>
      </Switch>
    </Div>
  );

  function _blurOnEnter(event) {
    if (event.key !== "Enter") {
      return;
    }
    searchInputNode.current.blur();
  }

  function _fuzzyMatch({ query }) {
    const formattedQuery = query.toLowerCase().replace(/[^a-z ]/g, "");
    if (formattedQuery.length < 3) {
      return;
      setCandidates(
        options.filter((option) =>
          option.name.toLowerCase().includes(formattedQuery)
        )
        // options.filter((option) => {
        //   let j = -1;
        //   for (let i = 0; i < formattedQuery.length; i++) {
        //     let c = formattedQuery[i];
        //     j = option.name.indexOf(c, j + 1);
        //     if (j == -1) {
        //       return false;
        //     }
        //   }
        //   return true;
        // })
      );
      return;
    }
    let uniqueTrigrams = {};
    const terms = formattedQuery.split(" ");
    for (let i = 0, tlen = terms.length; i < tlen; i++) {
      let trigrams = _trigrams({ term: terms[i] });
      for (const trigram in trigrams) {
        if (uniqueTrigrams[trigram] !== undefined) {
          uniqueTrigrams[trigram]++;
          continue;
        }
        uniqueTrigrams[trigram] = 1;
      }
    }
    let uniqueCandidates = {};
    for (const trigram in uniqueTrigrams) {
      const occurrences = uniqueTrigrams[trigram];
      let st = sectionTrigrams[trigram];
      if (st !== undefined) {
        for (
          let i = 0, stLen = st.length, candidate = st[0];
          i < stLen;
          candidate = st[++i]
        ) {
          if (uniqueCandidates[candidate] !== undefined) {
            uniqueCandidates[candidate] += 10000 * occurrences;
            continue;
          }
          uniqueCandidates[candidate] = 10000 * occurrences;
        }
      }
      let dt = dishTrigrams[trigram];
      if (dt === undefined) {
        continue;
      }
      for (
        let j = 0, dtLen = dt.length, candidate = dt[0];
        j < dtLen;
        candidate = dt[++j]
      ) {
        if (uniqueCandidates[candidate] !== undefined) {
          uniqueCandidates[candidate] += occurrences;
          continue;
        }
        uniqueCandidates[candidate] = occurrences;
      }
    }
    setCandidates(
      Object.entries(uniqueCandidates).sort((a, b) => {
        if (a[1] < b[1]) {
          return 1;
        }
        if (a[1] > b[1]) {
          return -1;
        }
        return 0;
      })
    );
  }

  function _trigrams({ term }) {
    const len = term.length;
    const trigrams = {};
    for (let i = 0; i < len - 2; i++) {
      trigrams[term.slice(i, i + 3)] = true;
    }
    return trigrams;
  }
}
