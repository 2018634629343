import loadScript from "load-script";
import React, { useEffect, useState } from "react";
import CreditCardPreview from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useHistory } from "react-router-dom";

import { resetCart, useCart } from "./CartContext.js";
import { useHours } from "./HoursContext.js";
import { useMenu } from "./MenuContext.js";
import { saveOrder, useOrders } from "./OrdersContext.js";
import {
  showErrorSnack,
  showInfoSnack,
  // showSuccessSnack,
  // showWarningSnack,
  useSnacks,
} from "./SnacksContext.js";
import { useStyles } from "./StylesContext.js";
import Cash from "./assets/Cash.js";
import CreditCard from "./assets/CreditCard.js";
import Delivery from "./assets/Delivery.js";
import ScheduledSend from "./assets/ScheduledSend.js";
import Send from "./assets/Send.js";
import Takeout from "./assets/Takeout.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";
import TelephoneInput from "./components/TelephoneInput.js";
import dst from "./lib/dst.js";
import useDebounce from "./lib/useDebounce.js";
import useViewport from "./lib/useViewport.js";

export default function Checkout() {
  const [apiLoaded, setAPILoaded] = useState(false);
  const {
    backgroundColorButton,
    backgroundColorButtonCTA,
    backgroundColorButtonDisabled,
    backgroundColorFeature,
    backgroundColorSwitch,
    backgroundColorSwitchAlternate,
    backgroundColorSwitchDisabled,
    colorButton,
    colorButtonCTA,
    colorButtonDisabled,
    colorFeature,
    colorSwitch,
    colorSwitchAlternate,
    colorSwitchDisabled,
  } = useStyles();
  const [cart, cartDispatch] = useCart();
  const [ccBlurred, setCCBlurred] = useState(true);
  const controller = new AbortController();
  const {
    cookingTimeOffset,
    deliveryTimeOffset,
    holidays,
    weeklyHours,
  } = useHours();
  const [ccCVC, setCCCVC] = useState("");
  const [ccExpiry, setCCExpiry] = useState("");
  const [ccFocusField, setCCFocusField] = useState("");
  const [ccName, setCCName] = useState("");
  const [ccNumber, setCCNumber] = useState("");
  const [dateTimeBlurred, setDateTimeBlurred] = useState(true);
  const [deliveryAddressModified, setDeliveryAddressModified] = useState(0);
  const [deliveryBlurred, setDeliveryBlurred] = useState(true);
  const debouncedDeliveryAddressModified = useDebounce(
    deliveryAddressModified,
    1000
  );
  const [deliveryFee, setDeliveryFee] = useState();
  const [
    deliveryFeeCalculationPending,
    setDeliveryFeeCalculationPending,
  ] = useState(false);
  const [
    deliveryFeeCalculationRateLimited,
    setDeliveryFeeCalculationRateLimited,
  ] = useState(false);
  const [detailsBlurred, setDetailsBlurred] = useState(true);
  const { dishes, sections, sides } = useMenu();
  const { height, width } = useViewport();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [orderTime, setOrderTime] = useState(new Date());
  const [orders, ordersDispatch] = useOrders();
  const [, snacksDispatch] = useSnacks();
  const [specialRequests, setSpecialRequests] = useState("");
  const [specialTime, setSpecialTime] = useState("");
  const wrappedWeeklyHours = [
    ...weeklyHours.reduce(
      (acc, dailyHours) => [
        ...acc,
        dailyHours.closeWeekday >= dailyHours.openWeekday
          ? dailyHours
          : {
              ...dailyHours,
              closeWeekday: dailyHours.closeWeekday + 7,
            },
      ],
      []
    ),
  ];

  const mostRecentOrder =
    orders.orders.length === 0
      ? undefined
      : orders.orders[orders.orders.length - 1];
  const [cardPayment, setCardPayment] = useState(
    mostRecentOrder === undefined ? true : mostRecentOrder.paid
  );
  const [cartASAPEnabled, setCartASAPEnabled] = useState(
    Object.keys(cart.items).every(
      (item) =>
        sections[dishes[item].section].hours === undefined ||
        sections[dishes[item].section].hours.some(
          ({
            endHour,
            endMinute,
            endWeekday,
            beginningHour,
            beginningMinute,
            beginningWeekday,
          }) =>
            (beginningWeekday < orderTime.getDay() ||
              (beginningWeekday === orderTime.getDay() &&
                beginningHour < orderTime.getHours()) ||
              (beginningWeekday === orderTime.getDay() &&
                beginningHour === orderTime.getHours() &&
                beginningMinute <= orderTime.getMinutes())) &&
            (endWeekday > orderTime.getDay() ||
              (endWeekday === orderTime.getDay() &&
                endHour > orderTime.getHours()) ||
              (endWeekday === orderTime.getDay() &&
                endHour === orderTime.getHours() &&
                endMinute >= orderTime.getMinutes()))
        )
    )
  );
  const deliveryCache = {};
  for (let i = orders.orders.length - 1; i >= 0; i--) {
    if (orders.orders[i].deliveryAddress === "") {
      continue;
    }
    deliveryCache.address = orders.orders[i].deliveryAddress;
    deliveryCache.address2 = orders.orders[i].deliveryAddress2;
    deliveryCache.city = orders.orders[i].deliveryCity;
    deliveryCache.state = orders.orders[i].deliveryState;
    deliveryCache.zip = orders.orders[i].deliveryZip;
    break;
  }
  const [deliveryAddress, setDeliveryAddress] = useState(
    deliveryCache.address ?? ""
  );
  const [deliveryAddress2, setDeliveryAddress2] = useState(
    deliveryCache.address2 ?? ""
  );
  const [deliveryCity, setDeliveryCity] = useState(
    deliveryCache.city ?? "Richmond"
  );
  const [deliveryState, setDeliveryState] = useState(
    deliveryCache.state ?? "IN"
  );
  const [deliveryZip, setDeliveryZip] = useState(deliveryCache.zip ?? "47374");
  const [orderName, setOrderName] = useState(
    mostRecentOrder === undefined ? "" : mostRecentOrder.name
  );
  const [orderPhoneNumber, setOrderPhoneNumber] = useState(
    mostRecentOrder === undefined
      ? ""
      : `(${mostRecentOrder.phoneNumber.slice(
          0,
          3
        )}) ${mostRecentOrder.phoneNumber.slice(
          3,
          6
        )} - ${mostRecentOrder.phoneNumber.slice(6)}`
  );

  // security vulnerability
  // need to pass cart items + quantities
  // otherwise can manipulate prices client-side
  const subtotal = Object.keys(cart.items).reduce(
    (acc, item) =>
      acc +
      (dishes[item].price ?? 0) * (cart.items[item].quantity ?? 0) +
      (dishes[item].secondaryPrice ?? 0) *
        (cart.items[item].secondaryQuantity ?? 0) +
      (Object.keys(cart.items[item].sides ?? {}).reduce(
        (acc, side) =>
          acc +
          (sides[side].price ?? 0) * cart.items[item].sides[side].quantity,
        0
      ) ?? 0),
    0
  );
  const [takeout, setTakeout] = useState(
    mostRecentOrder === undefined ||
      subtotal <
        (isNaN(parseInt(process.env.REACT_APP_deliveryMinimumOrder))
          ? 0
          : parseInt(process.env.REACT_APP_deliveryMinimumOrder))
      ? true
      : mostRecentOrder.deliveryAddress === ""
  );
  const [nowOpen, setNowOpen] = useState(_nowOpen({ orderTime, takeout }));
  const [asap, setASAP] = useState(cartASAPEnabled && nowOpen);
  const taxRateString = process.env.REACT_APP_taxRate.split(".")[1];
  let tax = Math.floor(
    ((subtotal + (takeout ? 0 : deliveryFee ?? 0)) * parseInt(taxRateString)) /
      Math.pow(10, taxRateString.length - 1)
  );
  if (tax % 10 >= 5) {
    tax += 10;
  }
  tax = Math.floor(tax / 10);
  const total = subtotal + (takeout ? 0 : deliveryFee ?? 0) + tax;
  const deliveryFeeString = `${Math.floor((deliveryFee ?? 0) / 100)}.${
    (deliveryFee ?? 0) % 100 < 10
      ? `0${(deliveryFee ?? 0) % 100}`
      : (deliveryFee ?? 0) % 100
  }`;
  const subtotalString = `${Math.floor(subtotal / 100)}.${
    subtotal % 100 < 10 ? `0${subtotal % 100}` : subtotal % 100
  }`;
  const taxString = `${Math.floor(tax / 100)}.${
    tax % 100 < 10 ? `0${tax % 100}` : tax % 100
  }`;
  const totalString = `${Math.floor(total / 100)}.${
    total % 100 < 10 ? `0${total % 100}` : total % 100
  }`;
  const [valid, setValid] = useState(false);
  const [validCCNumber, setValidCCNumber] = useState(false);
  // const [validType, setValidType] = useState(false);

  // deliveryAddressModified becomes redundant
  // useEffect(
  //   () => {
  //     if (takeout) {
  //       setValidType(true)
  //       return;
  //     }
  //     if (deliveryAddress.length < 4) {
  //       setValidType(false)
  //       return;
  //     }
  //     if (deliveryCity.length === 0) {
  //       setValidType(false)
  //       return;
  //     }
  //     if (
  //       !process.env.REACT_APP_validDeliveryZipCodes.split(",").some(
  //         (zip) => zip === deliveryZip
  //       )
  //     ) {
  //       setValidType(false)
  //       return;
  //     }
  //     setValidType(true)
  //   },
  //   [
  //     //
  //   ]
  // );

  useEffect(_calculateDeliveryFee, [debouncedDeliveryAddressModified]);
  useEffect(_cancelFetches, []);
  useEffect(_cartASAPEnabled, [cart, dishes, orderTime, sections]);
  useEffect(_loadAPI, []);
  useEffect(_rateLimitDeliveryFeeCalculation, [
    deliveryFeeCalculationRateLimited,
  ]);
  // useEffect(_startTicking, []);
  useEffect(_updateNowOpen, [orderTime, takeout]);
  useEffect(_validate, [
    asap,
    cardPayment,
    cart,
    ccCVC,
    ccExpiry,
    ccName,
    deliveryAddress,
    deliveryAddress2,
    deliveryAddressModified,
    deliveryCity,
    deliveryFee,
    deliveryFeeCalculationPending,
    deliveryState,
    deliveryZip,
    loading,
    orderName,
    orderPhoneNumber,
    specialTime,
    subtotal,
    takeout,
    validCCNumber,
  ]);

  return (
    <Div>
      <Div
        style={{
          overflowY: "auto",
          padding: 16,
        }}
      >
        <h1>Schedule</h1>
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Pressable
            disabled={!cartASAPEnabled || !nowOpen}
            innerStyle={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onDisabledPress={() =>
              showErrorSnack({
                body: "Closed or closing soon.",
                dispatch: snacksDispatch,
                duration: 10000,
              })
            }
            onPress={() => setASAP(true)}
            outerStyle={{
              backgroundColor:
                !cartASAPEnabled || !nowOpen
                  ? backgroundColorSwitchDisabled
                  : asap
                  ? backgroundColorSwitchAlternate
                  : backgroundColorSwitch,
              flexBasis: 0,
              flexShrink: 0,
              flexGrow: 1,
              padding: 16,
            }}
            pressScale={1.25}
          >
            <Send color={asap ? colorSwitchAlternate : colorSwitch} />
            <span
              style={{
                color: asap ? colorSwitchAlternate : colorSwitch,
                paddingTop: 16,
              }}
            >
              Now
            </span>
          </Pressable>
          <Pressable
            innerStyle={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onPress={() => setASAP(false)}
            outerStyle={{
              backgroundColor: asap
                ? backgroundColorSwitch
                : backgroundColorSwitchAlternate,
              flexBasis: 0,
              flexShrink: 0,
              flexGrow: 1,
              padding: 16,
            }}
            pressScale={1.25}
          >
            <ScheduledSend color={asap ? colorSwitch : colorSwitchAlternate} />
            <span
              style={{
                color: asap ? colorSwitch : colorSwitchAlternate,
                paddingTop: 16,
              }}
            >
              Future
            </span>
          </Pressable>
        </Div>
        {!asap && (
          <div>
            <label htmlFor="datetime">Schedule date/time</label>
            <input
              min={new Date().toISOString().slice(0, 16)}
              onBlur={() => setDateTimeBlurred(true)}
              onChange={(event) => setSpecialTime(event.target.value)}
              onFocus={() => setDateTimeBlurred(false)}
              onKeyDown={_onKeyDown}
              placeholder="Schedule date/time"
              style={{
                boxSizing: "border-box",
                fontSize: 20,
                height: 48,
                paddingLeft: 12,
                paddingRight: 12,
                width: width - 32,
              }}
              type="datetime-local"
              value={specialTime}
            />
          </div>
        )}
        <Div style={{ marginBottom: 32 }} />
        <h1>Type</h1>
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Pressable
            innerStyle={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onPress={() => setTakeout(true)}
            outerStyle={{
              backgroundColor: takeout
                ? backgroundColorSwitchAlternate
                : backgroundColorSwitch,
              flexBasis: 0,
              flexShrink: 0,
              flexGrow: 1,
              padding: 16,
            }}
            pressScale={1.25}
          >
            <Takeout color={takeout ? colorSwitchAlternate : colorSwitch} />
            <span
              style={{
                color: takeout ? colorSwitchAlternate : colorSwitch,
                paddingTop: 16,
              }}
            >
              Takeout
            </span>
          </Pressable>
          <Pressable
            disabled={
              subtotal < parseInt(process.env.REACT_APP_deliveryMinimumOrder)
            }
            innerStyle={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onDisabledPress={() =>
              showErrorSnack({
                body: `$${Math.floor(
                  process.env.REACT_APP_deliveryMinimumOrder / 100
                )} ${
                  process.env.REACT_APP_deliveryMinimumOrder % 100 === 0
                    ? ""
                    : `.${process.env.REACT_APP_deliveryMinimumOrder % 100}`
                } delivery minimum`,
                dispatch: snacksDispatch,
                duration: 10000,
              })
            }
            onPress={() => setTakeout(false)}
            outerStyle={{
              backgroundColor:
                subtotal < parseInt(process.env.REACT_APP_deliveryMinimumOrder)
                  ? backgroundColorSwitchDisabled
                  : takeout
                  ? backgroundColorSwitch
                  : backgroundColorSwitchAlternate,
              flexBasis: 0,
              flexShrink: 0,
              flexGrow: 1,
              padding: 16,
            }}
            pressScale={1.25}
          >
            <Delivery
              color={
                subtotal < parseInt(process.env.REACT_APP_deliveryMinimumOrder)
                  ? colorSwitchDisabled
                  : takeout
                  ? colorSwitch
                  : colorSwitchAlternate
              }
            />
            <span
              style={{
                color: takeout ? colorSwitch : colorSwitchAlternate,
                paddingTop: 16,
              }}
            >
              Delivery
            </span>
          </Pressable>
        </Div>
        {!takeout && (
          <>
            <input
              onBlur={_blurDelivery}
              onChange={(event) => {
                setDeliveryAddress(event.target.value);
                setDeliveryAddressModified(deliveryAddressModified + 1);
              }}
              onFocus={_focusDelivery}
              onKeyDown={_onKeyDown}
              placeholder="Address"
              style={{
                boxSizing: "border-box",
                marginBottom: 16,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
                width: width - 32,
              }}
              type="text"
              value={deliveryAddress}
            />
            <input
              onBlur={_blurDelivery}
              onChange={(event) => {
                setDeliveryAddress2(event.target.value);
                setDeliveryAddressModified(deliveryAddressModified + 1);
              }}
              onFocus={_focusDelivery}
              onKeyDown={_onKeyDown}
              placeholder="Address 2"
              style={{
                boxSizing: "border-box",
                marginBottom: 16,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
                width: width - 32,
              }}
              type="text"
              value={deliveryAddress2}
            />
            <Div
              style={{
                flexBasis: "auto",
                flexDirection: "row",
                flexGrow: 0,
                width: width - 32,
              }}
            >
              <input
                onBlur={_blurDelivery}
                onChange={(event) => {
                  setDeliveryAddressModified(deliveryAddressModified + 1);
                  setDeliveryCity(event.target.value);
                }}
                onFocus={_focusDelivery}
                onKeyDown={_onKeyDown}
                placeholder="City"
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexGrow: 3,
                  marginRight: 16,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                  width: 0,
                }}
                type="text"
                value={deliveryCity}
              />
              <select
                onBlur={_blurDelivery}
                onChange={(event) => {
                  setDeliveryAddressModified(deliveryAddressModified + 1);
                  setDeliveryState(event.target.value);
                }}
                onFocus={_focusDelivery}
                value={deliveryState}
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexGrow: 1,
                  marginRight: 16,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                  width: 0,
                }}
              >
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
              <input
                onBlur={_blurDelivery}
                onChange={(event) => {
                  setDeliveryAddressModified(deliveryAddressModified + 1);
                  setDeliveryZip(event.target.value);
                }}
                onFocus={_focusDelivery}
                onKeyDown={_onKeyDown}
                placeholder="Zip"
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexGrow: 2,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                  width: 0,
                }}
                type="text"
                value={deliveryZip}
              />
            </Div>
          </>
        )}
        <Div style={{ marginBottom: 32 }} />
        <h1>Payment</h1>
        <Div
          style={{
            flexBasis: "auto",
            flexDirection: "row",
            flexGrow: 0,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Pressable
            innerStyle={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onPress={() => setCardPayment(true)}
            outerStyle={{
              backgroundColor: cardPayment
                ? backgroundColorSwitchAlternate
                : backgroundColorSwitch,
              flexBasis: 0,
              flexShrink: 0,
              flexGrow: 1,
              padding: 16,
            }}
            pressScale={1.25}
          >
            <CreditCard
              color={cardPayment ? colorSwitchAlternate : colorSwitch}
            />
            <span
              style={{
                color: cardPayment ? colorSwitchAlternate : colorSwitch,
                paddingTop: 16,
              }}
            >
              Card
            </span>
          </Pressable>
          <Pressable
            innerStyle={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onPress={() => setCardPayment(false)}
            outerStyle={{
              backgroundColor: cardPayment
                ? backgroundColorSwitch
                : backgroundColorSwitchAlternate,
              flexBasis: 0,
              flexShrink: 0,
              flexGrow: 1,
              padding: 16,
            }}
            pressScale={1.25}
          >
            <Cash color={cardPayment ? colorSwitch : colorSwitchAlternate} />
            <span
              style={{
                color: cardPayment ? colorSwitch : colorSwitchAlternate,
                paddingTop: 16,
              }}
            >
              Cash
            </span>
          </Pressable>
        </Div>
        {cardPayment && (
          <Div
            style={{
              flexBasis: "auto",
              flexGrow: 0,
              marginTop: 16,
            }}
          >
            <CreditCardPreview
              callback={_validCCNumber}
              cvc={ccCVC}
              expiry={ccExpiry}
              focused={ccFocusField}
              name={ccName}
              number={ccNumber}
            />
            <input
              name="number"
              onBlur={_blurCC}
              onChange={_onChangeCC}
              onFocus={_onFocusCC}
              onKeyDown={_onKeyDown}
              placeholder="Card Number"
              style={{
                marginBottom: 16,
                marginTop: 16,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
              type="tel"
              value={ccNumber}
            />
            <input
              name="name"
              onBlur={_blurCC}
              onChange={_onChangeCC}
              onFocus={_onFocusCC}
              onKeyDown={_onKeyDown}
              placeholder="Name"
              style={{
                marginBottom: 16,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
              type="text"
              value={ccName}
            />
            <input
              name="expiry"
              onBlur={_blurCC}
              onChange={_onChangeCC}
              onFocus={_onFocusCC}
              onKeyDown={_onKeyDown}
              placeholder="Valid Thru"
              style={{
                marginBottom: 16,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
              type="tel"
              value={ccExpiry}
            />
            <input
              name="cvc"
              onBlur={_blurCC}
              onChange={_onChangeCC}
              onFocus={_onFocusCC}
              onKeyDown={_onKeyDown}
              placeholder="CVC"
              style={{
                marginBottom: 16,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
              type="tel"
              value={ccCVC}
            />
          </Div>
        )}
        <h1>Details</h1>
        <Div
          style={{
            flexBasis: "auto",
            flexGrow: 0,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Div
            style={{
              alignItems: "center",
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <input
              name="name"
              onBlur={_blurDetails}
              onChange={(event) => setOrderName(event.target.value)}
              onFocus={_focusDetails}
              onKeyDown={_onKeyDown}
              placeholder="Name order under"
              style={{
                flexGrow: 1,
                marginRight: cardPayment && ccName.length >= 3 ? 16 : 0,
                minWidth: 0,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 8,
              }}
              type="text"
              value={orderName}
            />
            {cardPayment && ccName.length >= 3 && (
              <Pressable
                innerStyle={{ color: colorButton }}
                onPress={() => setOrderName(ccName)}
                outerStyle={{
                  backgroundColor: backgroundColorButton,
                  paddingBottom: 8,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                }}
                pressScale={1.2}
              >
                Copy CC name
              </Pressable>
            )}
          </Div>
          <TelephoneInput
            onBlur={_blurDetails}
            onChangeText={setOrderPhoneNumber}
            onFocus={_focusDetails}
            onKeyDown={_onKeyDown}
            style={{
              marginBottom: 16,
              paddingBottom: 8,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
            }}
            value={orderPhoneNumber}
          />
          <input
            onBlur={_blurDetails}
            onChange={(event) => setSpecialRequests(event.target.value)}
            onFocus={_focusDetails}
            onKeyDown={_onKeyDown}
            placeholder="Special request[s]"
            style={{
              marginBottom: 16,
              paddingBottom: 8,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
            }}
            type="text"
            value={specialRequests}
          />
        </Div>
      </Div>
      {((ccBlurred && dateTimeBlurred && deliveryBlurred && detailsBlurred) ||
        height > 640) && (
        <>
          <Div
            style={{
              backgroundColor: backgroundColorFeature,
              color: colorFeature,
              flexBasis: "auto",
              flexGrow: 0,
              padding: 16,
            }}
          >
            <Div
              style={{
                flexBasis: "auto",
                flexDirection: "row",
                flexGrow: 0,
                justifyContent: "space-between",
                paddingBottom: 4,
                paddingTop: 4,
              }}
            >
              <Div
                style={{
                  flexBasis: "auto",
                  flexGrow: 0,
                }}
              >
                Subtotal:
              </Div>
              <Div
                style={{
                  flexBasis: "auto",
                  flexGrow: 0,
                  justifyContent: "flex-end",
                }}
              >
                {subtotalString}
              </Div>
            </Div>
            {
              // !takeout && validType && !loading && (
            }
            {!takeout && (
              <Div
                style={{
                  flexBasis: "auto",
                  flexDirection: "row",
                  flexGrow: 0,
                  justifyContent: "space-between",
                  paddingBottom: 4,
                  paddingTop: 4,
                }}
              >
                <Div
                  style={{
                    flexBasis: "auto",
                    flexGrow: 0,
                  }}
                >
                  Delivery Fee:
                </Div>
                <Div
                  style={{
                    flexBasis: "auto",
                    flexGrow: 0,
                    justifyContent: "flex-end",
                  }}
                >
                  {loading ||
                  deliveryAddressModified !== debouncedDeliveryAddressModified
                    ? ""
                    : deliveryFeeString}
                </Div>
              </Div>
            )}
            <Div
              style={{
                flexBasis: "auto",
                flexDirection: "row",
                flexGrow: 0,
                justifyContent: "space-between",
                paddingBottom: 4,
                paddingTop: 4,
              }}
            >
              <Div
                style={{
                  flexBasis: "auto",
                  flexGrow: 0,
                }}
              >
                Tax:
              </Div>
              <Div
                style={{
                  flexBasis: "auto",
                  flexGrow: 0,
                  justifyContent: "flex-end",
                }}
              >
                {taxString}
              </Div>
            </Div>
            <Div
              style={{
                flexBasis: "auto",
                flexDirection: "row",
                flexGrow: 0,
                justifyContent: "space-between",
                paddingBottom: 4,
                paddingTop: 4,
              }}
            >
              <Div
                style={{
                  flexBasis: "auto",
                  flexGrow: 0,
                }}
              >
                Total:
              </Div>
              <Div
                style={{
                  flexBasis: "auto",
                  flexGrow: 0,
                  justifyContent: "flex-end",
                }}
              >
                ${totalString}
              </Div>
            </Div>
          </Div>
          <Pressable
            innerStyle={{
              color: valid ? colorButtonCTA : colorButtonDisabled,
              fontSize: 24,
            }}
            disabled={!valid}
            onPress={_placeOrder}
            outerStyle={{
              backgroundColor: valid
                ? backgroundColorButtonCTA
                : backgroundColorButtonDisabled,
              padding: 16,
            }}
          >
            Place Order
          </Pressable>
        </>
      )}
    </Div>
  );

  function _blurCC() {
    setCCBlurred(true);
  }

  function _blurDelivery() {
    setDeliveryBlurred(true);
  }

  function _blurDetails() {
    setDetailsBlurred(true);
  }

  function _calculateDeliveryFee() {
    if (loading) {
      setDeliveryFeeCalculationPending(true);
      return _cancelFetches;
    }
    if (takeout) {
      setDeliveryFeeCalculationPending(true);
      return _cancelFetches;
    }
    if (
      deliveryAddress.length < 4 ||
      deliveryCity.length === 0 ||
      deliveryZip.length !== 5 ||
      isNaN(parseInt(deliveryZip))
    ) {
      setDeliveryFeeCalculationPending(true);
      return _cancelFetches;
    }
    // if (!validType) {
    //   return _cancelFetches;
    // }
    setLoading(true);
    (async function () {
      try {
        const response = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://${process.env.REACT_APP_apiBase}/delivery-fee`
            : `http://${process.env.REACT_APP_apiBase}:8080/delivery-fee`,
          {
            body: JSON.stringify({
              address: deliveryAddress,
              address2: deliveryAddress2,
              city: deliveryCity,
              state: deliveryState,
              zip: deliveryZip,
            }),
            method: "POST",
            signal: controller.signal,
          }
        );
        if (!response.ok) {
          if (response.status === 404) {
            showErrorSnack({
              body: "Address not found",
              dispatch: snacksDispatch,
            });
            setDeliveryFeeCalculationPending(true);
            return;
          }
          if (response.status === 429) {
            setDeliveryFeeCalculationRateLimited(true);
            setDeliveryFeeCalculationPending(true);
            setLoading(false);
            showErrorSnack({
              body: "Too many requests.\nWill retry in 10s.",
              dispatch: snacksDispatch,
            });
            return;
          }
          showErrorSnack({
            body: await response.text(),
            dispatch: snacksDispatch,
          });
          setLoading(false);
          setDeliveryFeeCalculationPending(true);
          return;
        }
        setDeliveryFee(parseInt(await response.text()));
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
    return _cancelFetches;
  }

  function _cancelFetches() {
    return () => controller.abort();
  }

  function _cartASAPEnabled() {
    setCartASAPEnabled(
      Object.keys(cart.items).every(
        (item) =>
          sections[dishes[item].section].hours === undefined ||
          sections[dishes[item].section].hours.some(
            ({
              endHour,
              endMinute,
              endWeekday,
              beginningHour,
              beginningMinute,
              beginningWeekday,
            }) =>
              (beginningWeekday < orderTime.getDay() ||
                (beginningWeekday === orderTime.getDay() &&
                  beginningHour < orderTime.getHours()) ||
                (beginningWeekday === orderTime.getDay() &&
                  beginningHour === orderTime.getHours() &&
                  beginningMinute <= orderTime.getMinutes())) &&
              (endWeekday > orderTime.getDay() ||
                (endWeekday === orderTime.getDay() &&
                  endHour > orderTime.getHours()) ||
                (endWeekday === orderTime.getDay() &&
                  endHour === orderTime.getHours() &&
                  endMinute >= orderTime.getMinutes()))
          )
      )
    );
  }

  function _firstName() {
    return ccName.split(" ")[0];
  }

  function _focusDelivery() {
    setDeliveryBlurred(false);
  }

  function _focusDetails() {
    setDetailsBlurred(false);
  }

  function _lastName() {
    return ccName.split(" ")[1];
  }

  function _loadAPI() {
    (function () {
      if (window.window.ConvergeEmbeddedPayment) {
        return;
      }
      loadScript(
        process.env.NODE_ENV === "production"
          ? "https://api.convergepay.com/hosted-payments/Checkout.js"
          : "https://api.demo.convergepay.com/hosted-payments/Checkout.js",
        (err, script) => {
          if (err) {
            console.error(err);
            return;
          }
          setAPILoaded(true);
        }
      );
    })();
  }

  function _nowOpen({ nowOpen, takeout }) {
    let beginningTime = new Date(orderTime);
    let endTime = new Date(orderTime);
    if (!takeout) {
      endTime.setMinutes(
        endTime.getMinutes() + (cookingTimeOffset + deliveryTimeOffset)
      );
    } else {
      endTime.setMinutes(endTime.getMinutes() + cookingTimeOffset);
    }
    return (
      !holidays.some(
        ({ beginning, end }) => beginning < beginningTime && end > endTime
      ) &&
      wrappedWeeklyHours.some(
        ({
          closeHour,
          closeMinute,
          closeWeekday,
          openHour,
          openMinute,
          openWeekday,
        }) =>
          (openWeekday < beginningTime.getDay() ||
            (openWeekday === beginningTime.getDay() &&
              openHour < beginningTime.getHours()) ||
            (openWeekday === beginningTime.getDay() &&
              openHour === beginningTime.getHours() &&
              openMinute <= beginningTime.getMinutes())) &&
          (closeWeekday > endTime.getDay() ||
            (closeWeekday === endTime.getDay() &&
              closeHour > endTime.getHours()) ||
            (closeWeekday === endTime.getDay() &&
              closeHour === endTime.getHours() &&
              closeMinute >= endTime.getMinutes()))
      )
    );
  }

  function _onChangeCC(event) {
    const { name, value } = event.target;
    switch (name) {
      case "cvc":
        if (value.length > 3) {
          return;
        }
        if (value !== "" && !/^[0-9\b]+$/.test(value)) {
          return;
        }
        setCCCVC(value);
        return;
      case "expiry":
        if (value.replace(/\//g, "").length > 4) {
          return;
        }
        if (value !== "" && !/^[0-9/\b]+$/.test(value)) {
          return;
        }
        setCCExpiry(value);
        return;
      case "number":
        if (value.replace(/\s/g, "").length > 16) {
          return;
        }
        if (value !== "" && !/^[0-9 \b]+$/.test(value)) {
          return;
        }
        setCCNumber(value);
        return;
      case "name":
        setCCName(value);
        return;
    }
  }

  function _onFocusCC(event) {
    setCCBlurred(false);
    setCCFocusField(event.target.name);
  }

  function _onKeyDown(event) {
    if (event.keyCode !== 13) {
      return;
    }
    event.target.setAttribute("readonly", "readonly");
    event.target.blur();
    event.target.removeAttribute("readonly");
  }

  async function _placeOrder() {
    if (!valid) {
      return;
    }
    setLoading(true);
    let orderID, transactionToken;
    const path = cardPayment
      ? "/request-transaction-token"
      : "/place-cash-order";

    try {
      const response = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://${process.env.REACT_APP_apiBase}${path}`
          : `http://${process.env.REACT_APP_apiBase}:8080${path}`,
        {
          body: JSON.stringify({
            items: Object.keys(cart.items).reduce((acc, item) => {
              if (cart.items[item].sides === undefined) {
                return [
                  ...acc,
                  {
                    ...cart.items[item],
                    id: item.toString(),
                  },
                ];
              }
              return [
                ...acc,
                {
                  ...cart.items[item],
                  id: item.toString(),
                  sides: Object.keys(cart.items[item].sides).reduce(
                    (acc, side) => [
                      ...acc,
                      {
                        ...cart.items[item].sides[side],
                        id: side.toString(),
                      },
                    ],
                    []
                  ),
                },
              ];
            }, []),
            name: orderName,
            phoneNumber: orderPhoneNumber.replace(/\D/g, ""),
            total: totalString,
            ...(cardPayment && {
              ccFirstName: _firstName(),
              ccLastName: _lastName(),
            }),
            ...(!takeout && {
              deliveryAddress,
              deliveryAddress2,
              deliveryCity,
              deliveryState,
            }),
            ...(specialRequests.length > 0 && { specialRequests }),
            ...(!asap && { specialTime: new Date(specialTime) }),
          }),
          method: "POST",
          signal: controller.signal,
        }
      );
      if (response.status === 400 || response.status === 403) {
        showErrorSnack({
          body: "Invalid input",
          dispatch: snacksDispatch,
          duration: 10000,
        });
        setLoading(false);
        return;
      }
      if (response.status === 500) {
        showErrorSnack({
          body: "Unknown error",
          dispatch: snacksDispatch,
          duration: 10000,
        });
        setLoading(false);
        return;
      }
      if (response.status === 401) {
        showErrorSnack({
          body: "Failed to acquire transaction token",
          dispatch: snacksDispatch,
          duration: 10000,
        });
        setLoading(false);
        return;
      }
      if (!response.ok) {
        throw new Error(response);
      }
      if (!cardPayment) {
        const json = await response.json();
        const orderID = json.order;
        const timeReady = asap ? new Date(json.created) : new Date(specialTime);
        if (asap) {
          if (takeout) {
            timeReady.setMinutes(timeReady.getMinutes() + cookingTimeOffset);
          } else {
            timeReady.setMinutes(
              timeReady.getMinutes() + cookingTimeOffset + deliveryTimeOffset
            );
          }
        }
        saveOrder({
          dispatch: ordersDispatch,
          id: orderID,
          items: cart.items,
          name: orderName,
          paid: false,
          phoneNumber: orderPhoneNumber.replace(/\D/g, ""),
          specialRequests,
          timePlaced: new Date(json.created),
          timeReady,
          total: totalString,
          ...(!takeout && {
            deliveryAddress,
            deliveryAddress2,
            deliveryCity,
            deliveryState,
            deliveryZip,
          }),
        });
        resetCart({ dispatch: cartDispatch });
        setLoading(false);
        history.push(`order/${orderID}`);
        return;
      }
      const data = await response.json();
      orderID = data.order;
      transactionToken = data.token;
    } catch (error) {
      console.error(error);
      setLoading(false);
      return _cancelFetches;
    }
    try {
      if (!apiLoaded) {
        _loadAPI();
      }
      const ccExpiry2 = ccExpiry.replace(/\//, "");
      window.ConvergeEmbeddedPayment.pay(
        {
          ssl_add_token: "y",
          ssl_card_number: ccNumber.replace(/ /g, ""),
          ssl_cvv2cvc2: ccCVC,
          ssl_exp_date: `${ccExpiry2.slice(0, -2)}/${ccExpiry2.slice(-2)}`,
          ssl_first_name: _firstName(),
          ssl_get_token: "y",
          ssl_invoice_number: orderID,
          ssl_last_name: _lastName(),
          ssl_merchant_txn_id: orderID,
          ssl_txn_auth_token: transactionToken,
        },
        {
          onApproval: async (approvalResponse) => {
            try {
              const response = await fetch(
                process.env.NODE_ENV === "production"
                  ? `https://${process.env.REACT_APP_apiBase}/checkout-js-response`
                  : `http://${process.env.REACT_APP_apiBase}:8080/checkout-js-relay`,
                {
                  body:
                    process.env.NODE_ENV === "production"
                      ? JSON.stringify({
                          elavonTransaction: approvalResponse.ssl_txn_id,
                        })
                      : JSON.stringify(approvalResponse),
                  method: "POST",
                  signal: controller.signal,
                }
              );
              // approval: 201
              // decline: 204
              // elavon error: 502
              // user error: 400
              // server error: 500
              // elavon timeout: 504
              if (
                response.status !== 201 &&
                response.status !== 204 &&
                response.status !== 400 &&
                response.status !== 500 &&
                response.status !== 502 &&
                response.status !== 504
              ) {
                throw new Error(response);
              }
              if (response.status === 504) {
                showErrorSnack({
                  body:
                    "Failed to process transaction.\nPlease refresh and try again.",
                  dispatch: snacksDispatch,
                  duration: 10000,
                });
                return;
              }
              if (response.status === 400) {
                showErrorSnack({
                  body: "Invalid input",
                  dispatch: snacksDispatch,
                  duration: 10000,
                });
                return;
              }
              if (response.status !== 201 && response.status !== 204) {
                showErrorSnack({
                  body: "Unknown error",
                  dispatch: snacksDispatch,
                  duration: 10000,
                });
                setLoading(false);
                return;
              }
              if (response.status === 204) {
                let errorShown = false;
                if (approvalResponse.ssl_result_message !== "APPROVAL") {
                  showErrorSnack({
                    body: approvalResponse.ssl_result_message,
                    dispatch: snacksDispatch,
                    duration: 10000,
                  });
                  errorShown = true;
                }
                if (approvalResponse.ssl_transaction_type !== "SALE") {
                  showErrorSnack({
                    body: `Invalid transaction type: ${approvalResponse.ssl_transaction_type}`,
                    dispatch: snacksDispatch,
                    duration: 10000,
                  });
                  errorShown = true;
                }
                if (approvalResponse.ssl_transaction_type !== "SALE") {
                  showErrorSnack({
                    body: `Invalid transaction type: ${approvalResponse.ssl_transaction_type}`,
                    dispatch: snacksDispatch,
                    duration: 10000,
                  });
                  errorShown = true;
                }
                const amountParts = approvalResponse.ssl_amount.split(".");
                if (
                  amountParts.length !== 2 ||
                  amountParts[1].length !== 2 ||
                  isNaN(amountParts[1]) ||
                  amountParts[0].length === 0 ||
                  isNaN(amountParts[0]) ||
                  amountParts[0] === 0
                ) {
                  showErrorSnack({
                    body: `Invalid amount: ${approvalResponse.ssl_amount}`,
                    dispatch: snacksDispatch,
                    duration: 10000,
                  });
                  errorShown = true;
                }
                if (isNaN(Date.parse(approvalResponse.ssl_txn_time))) {
                  showErrorSnack({
                    body: `Invalid transaction time: ${approvalResponse.ssl_txn_time}`,
                    dispatch: snacksDispatch,
                    duration: 10000,
                  });
                  errorShown = true;
                }
                if (!errorShown) {
                  showErrorSnack({
                    body: "Unknown error",
                    dispatch: snacksDispatch,
                    duration: 10000,
                  });
                }
                setLoading(false);
                return;
              }
              const created =
                approvalResponse.ssl_txn_time + (dst() ? " EDT" : " EST");
              const timeReady = asap
                ? new Date(created)
                : new Date(specialTime);
              if (asap) {
                if (takeout) {
                  timeReady.setMinutes(
                    timeReady.getMinutes() + cookingTimeOffset
                  );
                } else {
                  timeReady.setMinutes(
                    timeReady.getMinutes() +
                      cookingTimeOffset +
                      deliveryTimeOffset
                  );
                }
              }
              saveOrder({
                dispatch: ordersDispatch,
                id: orderID,
                items: cart.items,
                name: orderName,
                paid: true,
                phoneNumber: orderPhoneNumber.replace(/\D/g, ""),
                specialRequests,
                timePlaced: new Date(created),
                timeReady,
                total: approvalResponse.ssl_amount,
                transaction: approvalResponse.ssl_txn_id,
                transactionTime: new Date(approvalResponse.ssl_txn_time),
                ...(!takeout && {
                  deliveryAddress,
                  deliveryAddress2,
                  deliveryCity,
                  deliveryState,
                  deliveryZip,
                }),
              });
              resetCart({ dispatch: cartDispatch });
              setLoading(false);
              history.push(`order/${orderID}`);
            } catch (error) {
              console.error(error);
              setLoading(false);
              return _cancelFetches;
            }
            setLoading(false);
          },
          onDeclined: (response) => {
            showErrorSnack({
              body: response.ssl_result_message,
              dispatch: snacksDispatch,
              duration: 10000,
            });
            setLoading(false);
            return;
          },
          onError: (error) => {
            // don't know shape of response, this callback never fires
            showErrorSnack({
              body: JSON.stringify(error, null, 2),
              dispatch: snacksDispatch,
              duration: 10000,
            });
            setLoading(false);
            return;
          },
        }
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function _rateLimitDeliveryFeeCalculation() {
    if (!deliveryFeeCalculationRateLimited) {
      return;
    }
    let innerTimeout;
    const outerTimeout = setTimeout(() => {
      showInfoSnack({
        body: "Calculating delivery fee",
        dispatch: snacksDispatch,
      });
      _calculateDeliveryFee();
      setDeliveryFeeCalculationPending(false);
      innerTimeout = setTimeout(() => {
        setDeliveryFeeCalculationRateLimited(false);
      }, 10000);
    }, 10000);
    return () => {
      clearTimeout(outerTimeout);
      clearTimeout(innerTimeout);
    };
  }

  function _startTicking() {
    const timer = setInterval(_tick, 1000);
    return () => clearInterval(timer);
  }

  function _tick() {
    setOrderTime(new Date());
  }

  function _updateNowOpen() {
    setNowOpen(_nowOpen({ orderTime, takeout }));
  }

  function _validCCNumber(_, ok) {
    setValidCCNumber(ok);
  }

  function _validCVC({ cvc }) {
    return cvc.length === 3;
  }

  function _validDetails({ name, phoneNumber }) {
    const cleanPhoneNumber = phoneNumber.replace(/[(). -]/g, "");
    return (
      name.length >= 3 &&
      cleanPhoneNumber.length === 10 &&
      /^[0-9]+$/.test(cleanPhoneNumber)
    );
  }

  function _validExpiry({ expiry }) {
    const month = +expiry.slice(0, 2);
    if (month < 1 || month > 12) {
      return false;
    }
    const nowMonth = new Date().getMonth() + 1;
    const nowYear = new Date().getFullYear().toString().slice(-2);
    const year = +expiry.slice(-2);
    if (year < nowYear) {
      return false;
    }
    if (year === nowYear && month < nowMonth) {
      return false;
    }
    if (year - 20 > nowYear) {
      return false;
    }
    return true;
  }

  function _validOrder({ cart, specialTime, subtotal, takeout }) {
    if (
      !takeout &&
      subtotal < parseInt(process.env.REACT_APP_deliveryMinimumOrder)
    ) {
      return false;
    }
    let time = specialTime === undefined ? new Date() : new Date(specialTime);
    return (
      Object.keys(cart.items).length > 0 &&
      Object.keys(cart.items).every(
        (item) =>
          sections[dishes[item].section].addID === undefined ||
          cart.items[item].quantity ===
            Object.keys(cart.items[item].sides ?? []).reduce(
              (acc, side) => acc + cart.items[item].sides[side].quantity,
              0
            )
      ) &&
      Object.keys(cart.items).every(
        (item) =>
          sections[dishes[item].section].hours === undefined ||
          sections[dishes[item].section].hours.some(
            ({
              endHour,
              endMinute,
              endWeekday,
              beginningHour,
              beginningMinute,
              beginningWeekday,
            }) =>
              (beginningWeekday < time.getDay() ||
                (beginningWeekday === time.getDay() &&
                  beginningHour < time.getHours()) ||
                (beginningWeekday === time.getDay() &&
                  beginningHour === time.getHours() &&
                  beginningMinute <= time.getMinutes())) &&
              (endWeekday > time.getDay() ||
                (endWeekday === time.getDay() && endHour > time.getHours()) ||
                (endWeekday === time.getDay() &&
                  endHour === time.getHours() &&
                  endMinute >= time.getMinutes()))
          )
      )
    );
  }

  function _validPayment({ cash, cvc, expiry, name, validCCNumber }) {
    if (cash) {
      return true;
    }
    // not working
    // [\x20-\x7E\xA0-\xFF]
    // /^[\x09\x0A\x0D\x20-\x7E\xA0-\xFF]*$/
    // https://stackoverflow.com/questions/13130419/validate-a-subgroup-of-iso-8859#comment52241763_13130455
    // console.log(/^[\x09\x0A\x0D\x20-\x7E\xA0-\xFF]*$/.test(name));
    return (
      _validCVC({ cvc }) &&
      _validExpiry({ expiry }) &&
      name.length > 0 &&
      // /[^\u0000-\u00ff]/g.test(name) &&
      validCCNumber
    );
  }

  function _validSchedule({ asap, specialTime, takeout }) {
    let beginningTime = new Date();
    let endTime = new Date(beginningTime);
    if (!takeout) {
      if (!asap) {
        if (specialTime === undefined) {
          return false;
        }
        beginningTime = new Date(specialTime);
        endTime = new Date(specialTime);
        beginningTime.setMinutes(
          beginningTime.getMinutes() - (cookingTimeOffset + deliveryTimeOffset)
        );
        endTime.setMinutes(endTime.getMinutes() + deliveryTimeOffset);
      } else {
        endTime.setMinutes(
          endTime.getMinutes() + (cookingTimeOffset + deliveryTimeOffset)
        );
      }
    } else {
      if (!asap) {
        if (specialTime === undefined) {
          return false;
        }
        beginningTime = new Date(specialTime);
        endTime = new Date(specialTime);
        beginningTime.setMinutes(
          beginningTime.getMinutes() - cookingTimeOffset
        );
      } else {
        endTime.setMinutes(endTime.getMinutes() + cookingTimeOffset);
      }
    }
    return (
      !holidays.some(
        ({ beginning, end }) => beginning < beginningTime && end > endTime
      ) &&
      wrappedWeeklyHours.some(
        ({
          closeHour,
          closeMinute,
          closeWeekday,
          openHour,
          openMinute,
          openWeekday,
        }) =>
          (openWeekday < beginningTime.getDay() ||
            (openWeekday === beginningTime.getDay() &&
              openHour < beginningTime.getHours()) ||
            (openWeekday === beginningTime.getDay() &&
              openHour === beginningTime.getHours() &&
              openMinute <= beginningTime.getMinutes())) &&
          (closeWeekday > endTime.getDay() ||
            (closeWeekday === endTime.getDay() &&
              closeHour > endTime.getHours()) ||
            (closeWeekday === endTime.getDay() &&
              closeHour === endTime.getHours() &&
              closeMinute >= endTime.getMinutes()))
      )
    );
  }

  function _validType({
    debouncedDeliveryAddressModified,
    deliveryAddress,
    deliveryAddressModified,
    deliveryCity,
    deliveryFee,
    deliveryFeeCalculationPending,
    deliveryZip,
    forFeeCalculationIfNecessary = true,
    loading,
  } = {}) {
    if (takeout) {
      return true;
    }
    if (forFeeCalculationIfNecessary) {
      return (
        deliveryAddress.length > 3 &&
        deliveryCity.length !== 0 &&
        deliveryZip.length === 5 &&
        !isNaN(parseInt(deliveryZip))
      );
    }
    return (
      !loading &&
      !!deliveryFee &&
      deliveryAddressModified === debouncedDeliveryAddressModified &&
      !deliveryFeeCalculationPending
    );
  }

  function _validate() {
    setValid(
      !loading &&
        _validOrder({ cart, specialTime, subtotal, takeout }) &&
        _validSchedule({ asap, specialTime, takeout }) &&
        _validType({
          debouncedDeliveryAddressModified,
          deliveryAddress,
          deliveryAddressModified,
          deliveryCity,
          deliveryFee,
          deliveryFeeCalculationPending,
          deliveryZip,
          forFeeCalculationIfNecessary: false,
          loading,
        }) &&
        _validPayment({
          cash: !cardPayment,
          cvc: ccCVC,
          expiry: ccExpiry,
          name: ccName,
          validCCNumber,
        }) &&
        _validDetails({
          name: orderName,
          phoneNumber: orderPhoneNumber.replace(/\D/g, ""),
        })
    );
  }
}
