import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { showErrorSnack, useSnacks } from "../SnacksContext.js";

export default function TelephoneInput({
  onChange,
  onChangeText,
  onKeyDown,
  onMouseUp,
  value,
  ...props
}) {
  const [changes, setChanges] = useState(0);
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);
  const [selection, setSelection] = useState();
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [, snacksDispatch] = useSnacks();

  useLayoutEffect(() => {
    ref.current.selectionStart = cursor;
    ref.current.selectionEnd = cursor;
  }, [changes, cursor, ref]);

  useLayoutEffect(() => {
    if (
      !selection ||
      typeof selection?.selectionEnd !== "number" ||
      typeof selection?.selectionStart !== "number"
    ) {
      return;
    }
    ref.current.selectionStart = selection.selectionStart;
    ref.current.selectionEnd = selection.selectionEnd;
  }, [ref, selection]);

  return (
    <input
      onChange={_onChange}
      onKeyDown={_onKeyDown}
      onMouseUp={_onMouseUp}
      placeholder="Phone number"
      ref={ref}
      type="tel"
      value={value}
      {...props}
    />
  );

  function _formatOrderPhoneNumber(value) {
    const l = value.length;
    if (l > 10) {
      return;
    }
    if (l === 0) {
      return "";
    }
    if (l < 3) {
      return `(${value}`;
    }
    if (l < 6) {
      return `(${value.slice(0, 3)}) ${value.slice(3)}`;
    }
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)} - ${value.slice(6)}`;
  }

  function _onChange(event) {
    setChanges(changes + 1);
    const rawCurrent = event.target.value;
    const rawPrevious = value;
    const current = rawCurrent.replace(/\D/g, "");
    const previous = rawPrevious.replace(/\D/g, "");
    if (current.length > 10) {
      setSelection({
        selectionEnd,
        selectionStart,
      });
      showErrorSnack({
        body: "Phone number too long",
        dispatch: snacksDispatch,
        duration: 7500,
      });
      return;
    }
    if (selectionStart === selectionEnd) {
      if (current.length === previous.length) {
        if (event.target.selectionStart < selectionStart) {
          if (selectionStart === 1) {
            setCursor(1);
            return;
          }
          if (selectionStart < 7) {
            onChangeText?.(
              _formatOrderPhoneNumber(previous.slice(0, 2) + previous.slice(3))
            );
            setCursor(3);
            return;
          }
          onChangeText?.(
            _formatOrderPhoneNumber(previous.slice(0, 5) + previous.slice(6))
          );
          setCursor(8);
          return;
        }
        if (selectionStart < 7) {
          onChangeText?.(
            _formatOrderPhoneNumber(previous.slice(0, 3) + previous.slice(4))
          );
          setCursor(4);
          return;
        }
        onChangeText?.(
          _formatOrderPhoneNumber(previous.slice(0, 6) + previous.slice(7))
        );
        setCursor(9);
        return;
      }
      onChangeText?.(_formatOrderPhoneNumber(current));
      const start =
        rawPrevious.slice(0, selectionStart).replace(/\D/g, "").length +
        rawCurrent
          .slice(
            selectionStart,
            selectionEnd + rawCurrent.length - rawPrevious.length
          )
          .replace(/\D/g, "").length;
      if (current.length < previous.length) {
        if (event.target.selectionStart < selectionStart) {
          if (start < 4) {
            setCursor(start);
            return;
          }
          if (start < 7) {
            setCursor(start + 2);
            return;
          }
          setCursor(start + 5);
          return;
        }
        if (start < 3) {
          setCursor(start + 1);
          return;
        }
        if (start < 6) {
          setCursor(start + 3);
          return;
        }
        setCursor(start + 6);
        return;
      }
    }
    onChangeText?.(_formatOrderPhoneNumber(current));
    const start =
      rawPrevious.slice(0, selectionStart).replace(/\D/g, "").length +
      rawCurrent
        .slice(
          selectionStart,
          selectionEnd + rawCurrent.length - rawPrevious.length
        )
        .replace(/\D/g, "").length;
    if (start < 3) {
      setCursor(start + 1);
      return;
    }
    if (start < 6) {
      setCursor(start + 3);
      return;
    }
    setCursor(start + 6);
  }

  function _onKeyDown(event) {
    _updateSelection();
    onKeyDown?.(event);
  }

  function _onMouseUp(event) {
    _updateSelection();
    onMouseUp?.(event);
  }

  function _updateSelection() {
    setSelectionStart(ref.current.selectionStart);
    setSelectionEnd(ref.current.selectionEnd);
  }
}
