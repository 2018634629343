import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Div from "./components/Div.js";

export default function Fallback() {
  const history = useHistory();

  useEffect(_redirectToMenu);

  return (
    <Div style={{ padding: 16 }}>
      <h2>404</h2>
      Page not found. Redirecting to the menu...
    </Div>
  );

  function _redirectToMenu() {
    setTimeout(() => history.push("/menu"), 2500);
  }
}
