export default function dst() {
  // this assumes that dst starts at same time locally as eastern time
  // doesn't work for most of arizona and some usa territories
  // otherwise good for usa
  return (
    new Date().getTimezoneOffset() <
    Math.max(
      new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset(),
      new Date(new Date().getFullYear(), 6, 1).getTimezoneOffset()
    )
  );
}
