import { createContext, useContext, useReducer } from "react";

const SnacksStateContext = createContext();
const SnacksDispatchContext = createContext();

export function SnacksProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, []);

  return (
    <SnacksStateContext.Provider value={state}>
      <SnacksDispatchContext.Provider value={dispatch}>
        {children}
      </SnacksDispatchContext.Provider>
    </SnacksStateContext.Provider>
  );
}

export function dismissSnack({ dispatch, index }) {
  dispatch({ payload: { index }, type: "DISMISS_SNACK" });
}

export function showErrorSnack({ body, dispatch, duration = 2000 }) {
  dispatch({ payload: { body, dispatch, duration }, type: "SHOW_ERROR" });
}

export function showInfoSnack({ body, dispatch, duration = 2000 }) {
  dispatch({ payload: { body, dispatch, duration }, type: "SHOW_INFO" });
}

export function showWarningSnack({ body, dispatch, duration = 2000 }) {
  dispatch({ payload: { body, dispatch, duration }, type: "SHOW_WARNING" });
}

export function showSucessSnack({ body, dispatch, duration = 2000 }) {
  dispatch({ payload: { body, dispatch, duration }, type: "SHOW_SUCCESS" });
}

export function useSnacks() {
  return [useSnacksState(), useSnacksDispatch()];
}

function reducer(state, action) {
  switch (action.type) {
    case "DISMISS_SNACK": {
      return [
        ...state.slice(0, action.payload.index),
        {
          ...state[action.payload.index],
          active: false,
        },
        ...state.slice(action.payload.index + 1),
      ];
    }
    case "SHOW_ERROR":
      setTimeout(
        () =>
          dismissSnack({
            dispatch: action.payload.dispatch,
            index: state.length,
          }),
        action.payload.duration
      );
      return [
        ...state,
        {
          active: true,
          body: action.payload.body,
          type: "error",
        },
      ];
    case "SHOW_INFO":
      setTimeout(
        () =>
          dismissSnack({
            dispatch: action.payload.dispatch,
            index: state.length,
          }),
        action.payload.duration
      );
      return [
        ...state,
        {
          active: true,
          body: action.payload.body,
          type: "info",
        },
      ];
    case "SHOW_SUCCESS":
      setTimeout(
        () =>
          dismissSnack({
            dispatch: action.payload.dispatch,
            index: state.length,
          }),
        action.payload.duration
      );
      return [
        ...state,
        {
          active: true,
          body: action.payload.body,
          type: "success",
        },
      ];
    case "SHOW_WARNING":
      setTimeout(
        () =>
          dismissSnack({
            dispatch: action.payload.dispatch,
            index: state.length,
          }),
        action.payload.duration
      );
      return [
        ...state,
        {
          active: true,
          body: action.payload.body,
          type: "warning",
        },
      ];
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useSnacksState() {
  const context = useContext(SnacksStateContext);
  if (context === undefined) {
    throw new Error("useSnacksState must be used within a SnacksProvider");
  }
  return context;
}

function useSnacksDispatch() {
  const context = useContext(SnacksDispatchContext);
  if (context === undefined) {
    throw new Error("useSnacksDispatch must be used within a SnacksProvider");
  }
  return context;
}
