import { useHistory, useRouteMatch } from "react-router-dom";

import { useMenu } from "./MenuContext.js";
import { useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";

export default function MenuSectionRow({ id, name, nameChinese, note }) {
	const { backgroundColorBody, colorBody, colorSecondary } = useStyles();
	const history = useHistory();
	let { path } = useRouteMatch();
	const { sections } = useMenu();

	return (
		<Pressable
			innerStyle={{
				color: colorBody,
				transformOrigin: "center left",
				width: "100%",
			}}
			onPress={() => history.push(`${path}/section/${id}`)}
			outerStyle={{
				backgroundColor: backgroundColorBody,
				overflowY: "hidden",
			}}
			pressScale={1.2}
		>
			<Div
				style={{
					alignItems: "center",
					flexBasis: "auto",
					flexDirection: "row",
					flexGrow: 0,
					padding: 16,
				}}
			>
				{!!sections[id].picture && (
					<img
						height={40}
						src={
							process.env.NODE_ENV === "production"
								? `/pictures/${sections[id].picture}`
								: `http://${process.env.REACT_APP_apiBase}:8080/pictures/${sections[id].picture}`
						}
						style={{ marginRight: 16 }}
					/>
				)}
				<Div>
					<Div
						style={{
							alignItems: "center",
							flexBasis: "auto",
							flexDirection: "row",
							flexGrow: 0,
							fontSize: 24,
						}}
					>
						{name}&nbsp;
						<span
							style={{
								color: colorSecondary,
								fontSize: 16,
							}}
						>
							({nameChinese})
						</span>
					</Div>
					{!!note && (
						<Div
							style={{
								color: colorSecondary,
								flexBasis: "auto",
								flexDirection: "row",
							}}
						>
							{note}
						</Div>
					)}
				</Div>
			</Div>
		</Pressable>
	);
}
