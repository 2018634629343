import { useEffect, useState } from "react";

export default function useViewport() {
  const [breakpoint, setBreakpoint] = useState(620);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(Math.min(window.innerWidth, 960));

  const [mobile, setMobile] = useState(
    height < breakpoint || width < breakpoint
  );
  const [portrait, setPortrait] = useState(height > width);

  useEffect(_subscribeWindowResize, [breakpoint, height, width]);

  return { breakpoint, height, mobile, portrait, setBreakpoint, width };

  function _subscribeWindowResize() {
    window.addEventListener("resize", _handleWindowResize);

    return () => window.removeEventListener("resize", _handleWindowResize);

    function _handleWindowResize() {
      const { innerHeight: newHeight, innerWidth: newFullWidth } = window;
      const newWidth = Math.min(newFullWidth, 960);
      if (newHeight === height && newWidth === width) {
        return;
      }
      setHeight(newHeight);
      setMobile(newHeight < breakpoint || newWidth < breakpoint);
      setPortrait(newHeight > newWidth);
      setWidth(newWidth);
    }
  }
}
