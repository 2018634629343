import loadScript from "load-script";
import { useEffect, useRef, useState } from "react";

import { useHours } from "./HoursContext.js";
import Div from "./components/Div.js";
import useApple from "./lib/useApple.js";
import useViewport from "./lib/useViewport.js";

export default function About() {
  const apple = useApple();
  const deliveryMapNode = useRef();
  const [googleMapsAPILoaded, setGoogleMapsAPILoaded] = useState(false);
  const { holidays, weeklyHours } = useHours();
  const { mobile, width } = useViewport();
  const now = new Date();
  const day = now.getDay();

  useEffect(_loadGoogleMapsAPI, []);
  useEffect(_drawMap, [googleMapsAPILoaded]);

  return (
    <Div style={{ overflowY: "auto", padding: 16 }}>
      <h1>Great Wall</h1>
      <h2>Phone</h2>
      <a href="tel:7659625255">
        <h3>(765) 962 - 5255</h3>
      </a>
      <h2>Address</h2>
      <a
        href={
          apple
            ? `http://maps.apple.com/?${
                mobile ? "daddr" : "address"
              }=Great+Wall+1731+National+Rd+W+Richmond,+IN+47374&t=m`
            : mobile
            ? "https://www.google.com/maps/dir/?api=1&destination=Great+Wall+1731+National+Rd+W+Richmond,+IN+47374&travelmode=driving"
            : "http://www.google.com/maps?q=Great+Wall+1731+National+Rd+W+Richmond,+IN+47374"
        }
        rel="noreferrer"
        target="_blank"
      >
        <div>1731 National Rd W</div>
        <div style={{ marginTop: 8 }}>Richmond, IN 47374</div>
      </a>
      <WeeklyHours day={day} weekdays={weekdays} weeklyHours={weeklyHours} />
      <Holidays holidays={holidays} now={now} />
      <h2>Delivery</h2>
      <h3>Range</h3>
      5 miles
      <Div
        ref={deliveryMapNode}
        style={{
          flexBasis: "auto",
          flexGrow: 0,
          height: width - 32,
          marginBottom: 16,
          marginTop: 16,
          width: width - 32,
        }}
      />
      <h3>Minimum order</h3>$
      {Math.floor(process.env.REACT_APP_deliveryMinimumOrder / 100)}
      {process.env.REACT_APP_deliveryMinimumOrder % 100 === 0
        ? ""
        : `.${process.env.REACT_APP_deliveryMinimumOrder % 100}`}
      <h3>Fee</h3>
      <div
        style={{
          maxWidth: 120,
          minWidth: 120,
          width: 120,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <div>&lt;2 miles</div>
          <div>$4</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <div>&lt;3 miles</div>
          <div>$5</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <div>&lt;4 miles</div>
          <div>$6</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <div>&lt;5 miles</div>
          <div>$7</div>
        </div>
      </div>
      <h2>Third-party software licenses</h2>
      <ul
        style={{
          listStyle: "none",
          marginBottom: 0,
          marginTop: 0,
          paddingLeft: 0,
        }}
      >
        <li>
          <a
            href="https://github.com/eldargab/load-script/tree/09f6c1002c3bc48e8ab3027ad42260228b5dd0db#license"
            target="_blank"
          >
            load-script
          </a>
        </li>
        <li style={{ marginTop: 16 }}>
          <a
            href="https://raw.githubusercontent.com/facebook/react/v17.0.2/LICENSE"
            target="_blank"
          >
            react
          </a>
        </li>
        <li style={{ marginTop: 16 }}>
          <a
            href="https://raw.githubusercontent.com/amaroteam/react-credit-cards/43ebc239c6d56b0f924816aca12f492201c38acd/LICENSE.md"
            target="_blank"
          >
            react-credit-cards
          </a>
        </li>
        <li style={{ marginTop: 16 }}>
          <a
            href="https://raw.githubusercontent.com/facebook/react/v17.0.2/LICENSE"
            target="_blank"
          >
            react-dom
          </a>
        </li>
        <li style={{ marginTop: 16 }}>
          <a
            href="https://raw.githubusercontent.com/remix-run/react-router/v5.2.0/LICENSE"
            target="_blank"
          >
            react-router-dom
          </a>
        </li>
      </ul>
    </Div>
  );

  function _drawMap() {
    if (!googleMapsAPILoaded) {
      return;
    }
    const map = new window.google.maps.Map(deliveryMapNode.current, {
      center: { lat, lng },
      clickableIcons: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
      mapTypeControl: false,
      minZoom: 11,
      restriction: {
        latLngBounds: {
          east: lng + 0.12,
          north: lat + 0.1,
          south: lat - 0.1,
          west: lng - 0.12,
        },
      },
      rotateControlOptions: false,
      scaleControlOptions: false,
      streetViewControl: false,
      tilt: false,
      zoom: 11,
    });
    new window.google.maps.Circle({
      center: { lat, lng },
      fillColor: "red",
      fillOpacity: 0.35,
      map,
      radius: 5 * 1609.34,
      strokeColor: "red",
      strokeOpacity: 0.8,
      strokeWeight: 2,
    });
    new window.google.maps.Marker({
      map,
      position: { lat, lng },
      label: {
        fontSize: "20px",
        text: "Great Wall",
      },
    });
    new window.google.maps.TrafficLayer({
      map,
    });
  }

  function _loadGoogleMapsAPI() {
    if (process.env.NODE_ENV === "production") {
      _load();
    }

    async function _load() {
      if (!!window.google?.maps) {
        setGoogleMapsAPILoaded(true);
        return;
      }
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_googleMapsAPIKey}&v=3.44`,
        (err, script) => {
          if (err) {
            console.error(err);
            return;
          }
          setGoogleMapsAPILoaded(true);
        }
      );
    }
  }
}

function Holidays({ holidays, now }) {
  if (holidays.length === 0) {
    return null;
  }
  return (
    <Div
      style={{
        flexBasis: "auto",
        flexGrow: 0,
      }}
    >
      <h2>Holiday Closings</h2>
      {holidays.map((holiday, key) => {
        const { beginning, end } = holiday;
        const endDay = new Date(end);
        if (
          endDay.getHours() === 0 &&
          endDay.getMinutes() === 0 &&
          endDay.getSeconds() === 0
        ) {
          endDay.setDate(endDay.getDate() - 1);
        }
        return (
          <Div
            key={key}
            style={{
              flexBasis: "auto",
              flexGrow: 0,
              marginBottom: 16,
              marginTop: key === 0 ? 0 : 16,
            }}
          >
            {`${beginning.getMonth() + 1}/${beginning.getDate()}${
              beginning.getFullYear() !== now.getFullYear()
                ? `/${beginning.getFullYear()}`
                : ""
            }${
              beginning.getHours() !== 0 ||
              beginning.getMinutes() !== 0 ||
              beginning.getSeconds() !== 0
                ? ` ${
                    beginning.getHours() % 12 === 0
                      ? 12
                      : (beginning.getHours() + 12) % 12
                  }:${
                    (beginning.getMinutes() < 10 ? "0" : "") +
                    beginning.getMinutes()
                  }${beginning.getHours() < 12 ? "am" : "pm"}`
                : ""
            }`}{" "}
            -{" "}
            {`${endDay.getMonth() + 1}/${endDay.getDate()}${
              endDay.getFullYear() !== now.getFullYear()
                ? `/${endDay.getFullYear()}`
                : ""
            }${
              end.getHours() !== 0 ||
              end.getMinutes() !== 0 ||
              end.getSeconds() !== 0
                ? ` ${
                    end.getHours() % 12 === 0 ? 12 : (end.getHours() + 12) % 12
                  }:${(end.getMinutes() < 10 ? "0" : "") + end.getMinutes()}${
                    end.getHours() < 12 ? "am" : "pm"
                  }`
                : ""
            }`}
          </Div>
        );
      })}
    </Div>
  );
}

function WeeklyHours({ day, weekdays, weeklyHours }) {
  return (
    <Div
      style={{
        flexBasis: "auto",
        flexGrow: 0,
        maxWidth: 240,
        minWidth: 240,
        width: 240,
      }}
    >
      <h2>Weekly Hours</h2>
      {[...Array(7).keys()].map((offset) => {
        let weekday = (day + 7 + offset) % 7;
        const dayHours = weeklyHours.filter(
          (dailyHours) => dailyHours.openWeekday === weekday
        );
        return (
          <Div
            key={offset}
            style={{
              flexBasis: "auto",
              flexDirection: "row",
              flexGrow: 0,
              justifyContent: "space-between",
              paddingBottom: 4,
              paddingTop: 4,
            }}
          >
            <Div
              style={{
                flexBasis: "auto",
                flexGrow: 0,
              }}
            >
              {weekdays[weekday]}
            </Div>
            {dayHours.length
              ? dayHours.map((openWindow, key) => (
                  <Div
                    key={key}
                    style={{
                      flexBasis: "auto",
                      flexGrow: 0,
                      paddingTop: key !== 0 ? 8 : 0,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Div
                      style={{
                        flexBasis: "auto",
                        flexGrow: 0,
                      }}
                    >
                      {`${openWindow.openHour % 12}${
                        openWindow.openMinute === 0
                          ? ""
                          : openWindow.openMinute < 10
                          ? `:0${openWindow.openMinute}`
                          : `:${openWindow.openMinute}`
                      }${openWindow.openHour < 12 ? "am" : "pm"} - ${
                        openWindow.closeHour % 12
                      }${
                        openWindow.closeMinute === 0
                          ? ""
                          : openWindow.closeMinute < 10
                          ? `:0${openWindow.closeMinute}`
                          : `:${openWindow.closeMinute}`
                      }${openWindow.closeHour < 12 ? "am" : "pm"}`}
                    </Div>
                  </Div>
                ))
              : "Closed"}
          </Div>
        );
      })}
    </Div>
  );
}

const lat = parseFloat(process.env.REACT_APP_restaurantLatitude);
const lng = parseFloat(process.env.REACT_APP_restaurantLongitude);

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
