import { useEffect, useState } from "react";

import {
  decreaseItemSideQuantity,
  increaseItemSideQuantity,
  useCart,
} from "./CartContext.js";
import { useMenu } from "./MenuContext.js";
import { useStyles } from "./StylesContext.js";
import Div from "./components/Div.js";
import Pressable from "./components/Pressable.js";
import useViewport from "./lib/useViewport.js";

export default function DishSidesModal({ hide, id }) {
  const {
    backgroundColorBody,
    backgroundColorButton,
    backgroundColorButtonCTA,
    colorButton,
    colorButtonCTA,
    colorSecondary,
  } = useStyles();
  const [cart, cartDispatch] = useCart();
  const [dirty, setDirty] = useState(false);
  const { dishes, sections, sides } = useMenu();
  const { height, width } = useViewport();
  const [missingSides, setMissingSides] = useState(
    cart.items[id].quantity - sidesQuantity(cart.items[id].sides)
  );
  useEffect(() => {
    const ms = cart.items[id].quantity - sidesQuantity(cart.items[id].sides);
    setMissingSides(ms);
    if (dirty && ms === 0) {
      hide();
    }
  }, [cart.items[id].quantity, cart.items[id].sides, dirty]);

  return (
    <Div
      onClick={hide}
      style={{
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        bottom: 0,
        height,
        justifyContent: "center",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
      }}
    >
      <Div
        onClick={(event) => {
          event.stopPropagation();
        }}
        style={{
          backgroundColor: backgroundColorBody,
          height: (height * 3) / 4,
          width: width - 32,
        }}
      >
        {missingSides > 0 ? (
          <Div>
            <Div
              style={{
                alignItems: "center",
                boxShadow:
                  "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
                display: "block",
                flexBasis: "auto",
                flexGrow: 0,
                fontSize: 20,
                marginBottom: 16,
                padding: 16,
              }}
            >
              Pick {missingSides}
              {sidesQuantity(cart.items[id]?.sides) === 0 ? " " : " more "}side
              {missingSides === 1 ? "" : "s"} for&nbsp;
              {dishes[id].name}&nbsp;
              <span style={{ color: colorSecondary }}>
                ({dishes[id].nameChinese})
              </span>
            </Div>
            <Div
              style={{
                overflowY: "auto",
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              {Object.keys(sides)
                .filter(
                  (side) =>
                    sides[side].addID === sections[dishes[id].section].addID
                )
                .map((side) => (
                  <Div
                    key={side}
                    style={{
                      flexBasis: "auto",
                      flexGrow: 0,
                      marginBottom: 32,
                      marginTop: 32,
                    }}
                  >
                    <Div
                      key={side}
                      style={{
                        alignItems: "center",
                        flexBasis: "auto",
                        flexDirection: "row",
                        flexGrow: 0,
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        {sides[side].name}{" "}
                        <span style={{ color: colorSecondary }}>
                          ({sides[side].nameChinese})
                        </span>
                      </span>
                      {sides[side].price === undefined
                        ? ""
                        : `$${Math.floor(sides[side].price / 100)}.${
                            sides[side].price % 100 < 10
                              ? `0${sides[side].price % 100}`
                              : sides[side].price % 100
                          }`}
                    </Div>
                    <Div
                      style={{
                        flexBasis: "auto",
                        flexDirection: "row",
                        flexGrow: 0,
                        justifyContent: "space-around",
                        marginTop: 16,
                      }}
                    >
                      <Div
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {!!cart.items[id]?.sides?.[side]?.quantity ? (
                          <Pressable
                            innerStyle={{ color: colorButton }}
                            onPress={() => {
                              decreaseItemSideQuantity({
                                dispatch: cartDispatch,
                                item: id,
                                side,
                              });
                              setDirty(true);
                            }}
                            outerStyle={{
                              backgroundColor: backgroundColorButton,
                              paddingBottom: 8,
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingTop: 8,
                            }}
                          >
                            -
                          </Pressable>
                        ) : (
                          <Div
                            style={{
                              alignItems: "center",
                              flexBasis: "auto",
                              flexDirection: "row",
                              flexGrow: 0,
                              justifyContent: "center",
                              padding: 8,
                            }}
                          ></Div>
                        )}
                      </Div>
                      <Div
                        style={{
                          alignItems: "center",
                          flexBasis: "auto",
                          flexDirection: "row",
                          flexGrow: 0,
                          justifyContent: "center",
                          maxWidth: 80,
                          minWidth: 80,
                          width: 80,
                        }}
                      >
                        {cart.items[id]?.sides?.[side]?.quantity}
                      </Div>
                      <Div
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Pressable
                          innerStyle={{ color: colorButtonCTA }}
                          onPress={() => {
                            increaseItemSideQuantity({
                              dispatch: cartDispatch,
                              item: id,
                              side,
                            });
                            setDirty(true);
                          }}
                          outerStyle={{
                            backgroundColor: backgroundColorButtonCTA,
                            paddingBottom: 8,
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 8,
                          }}
                        >
                          +
                        </Pressable>
                      </Div>
                    </Div>
                  </Div>
                ))}
            </Div>
          </Div>
        ) : (
          <Div>
            <Div
              style={{
                alignItems: "center",
                boxShadow:
                  "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
                display: "block",
                flexBasis: "auto",
                flexGrow: 0,
                fontSize: 20,
                marginBottom: 16,
                padding: 16,
              }}
            >
              {missingSides === 0 ? (
                <>
                  Side{cart.items[id].quantity === 1 ? "" : "s"} for{" "}
                  {dishes[id].name}&nbsp;
                  <span style={{ color: colorSecondary }}>
                    ({dishes[id].nameChinese})
                  </span>
                </>
              ) : (
                <>
                  Remove {Math.abs(missingSides)} side
                  {missingSides === -1 ? " " : "s "}
                  for {dishes[id].name}{" "}
                  <span style={{ color: colorSecondary }}>
                    ({dishes[id].nameChinese})
                  </span>
                </>
              )}
            </Div>
            <Div style={{ overflowY: "auto" }}>
              {Object.keys(cart.items[id].sides).map((side) => (
                <Div
                  key={side}
                  style={{
                    flexBasis: "auto",
                    flexDirection: "row",
                    flexGrow: 0,
                    justifyContent: "space-around",
                    marginTop: 32,
                    marginBottom: 32,
                  }}
                >
                  <Div
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Pressable
                      innerStyle={{
                        color: missingSides < 0 ? colorButtonCTA : colorButton,
                      }}
                      onPress={() => {
                        decreaseItemSideQuantity({
                          dispatch: cartDispatch,
                          item: id,
                          side,
                        });
                        setDirty(true);
                      }}
                      outerStyle={{
                        backgroundColor:
                          missingSides < 0
                            ? backgroundColorButtonCTA
                            : backgroundColorButton,
                        paddingBottom: 8,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                      }}
                    >
                      -
                    </Pressable>
                  </Div>
                  <Div
                    style={{
                      flexGrow: 2,
                      justifyContent: "center",
                      paddingRight: 16,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span
                      style={{
                        overflowX: "auto",
                        paddingBottom: 4,
                        paddingTop: 4,
                      }}
                    >
                      {cart.items[id].sides[side].quantity} with{" "}
                      {sides[side].name}{" "}
                      <span style={{ color: colorSecondary }}>
                        ({sides[side].nameChinese})
                      </span>
                    </span>
                  </Div>
                </Div>
              ))}
            </Div>
          </Div>
        )}
        {missingSides === 0 ? (
          <Pressable
            innerStyle={{ color: colorButtonCTA }}
            onPress={hide}
            outerStyle={{
              backgroundColor: backgroundColorButtonCTA,
              margin: 16,
              padding: 8,
            }}
          >
            Done
          </Pressable>
        ) : null}
      </Div>
    </Div>
  );
}

function sidesQuantity(sides) {
  if (sides === undefined) {
    return 0;
  }
  return Object.keys(sides).reduce((acc, cur) => acc + sides[cur].quantity, 0);
}
