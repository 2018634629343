import React, { createContext, useContext, useEffect, useState } from "react";

import Div from "./components/Div.js";

const MenuContext = createContext();

export function MenuProvider({ children }) {
  const [dishes, setDishes] = useState([]);
  const [dishTrigrams, setDishTrigrams] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [sections, setSections] = useState([]);
  const [sectionTrigrams, setSectionTrigrams] = useState([]);
  const [sides, setSides] = useState([]);

  useEffect(_pollIfStale, []);
  useEffect(_rehydrate, []);

  if (!loaded) {
    return (
      <Div style={{ alignItems: "center", justifyContent: "center" }}>
        Loading menu
      </Div>
    );
  }

  return (
    <MenuContext.Provider
      value={{
        dishes,
        dishTrigrams,
        downloadIfStale: _downloadIfStale,
        sections,
        sectionTrigrams,
        sides,
      }}
    >
      {children}
    </MenuContext.Provider>
  );

  function _pollIfStale() {
    const timer = setInterval(_downloadIfStale, 300000);
    return () => clearInterval(timer);
  }

  function _downloadIfStale() {
    _fetch({ lastModified: localStorage.getItem("menuModified") });
  }

  function _fetch({ lastModified } = {}) {
    const controller = new AbortController();

    (async function ({ prevLastModified }) {
      try {
        const response = await fetch(
          process.env.NODE_ENV === "production"
            ? `https://${process.env.REACT_APP_apiBase}/menu`
            : `http://${process.env.REACT_APP_apiBase}:8080/menu`,
          !!prevLastModified
            ? {
                body: prevLastModified,
                headers: {
                  "Content-Type": "text/plain",
                },
                method: "POST",
                signal: controller.signal,
              }
            : {
                method: "POST",
                signal: controller.signal,
              }
        );
        if (response.status === 304) {
          return;
        }
        const lastModified = response.headers.get("Last-Modified");
        const decodedResponse = await response.json();
        localStorage.setItem("menu", JSON.stringify(decodedResponse));
        localStorage.setItem("menuModified", lastModified);
        const {
          dishes: d,
          dishTrigrams: dt,
          sections: se,
          sectionTrigrams: st,
          sides: si,
        } = decodedResponse;
        setDishes(d);
        setDishTrigrams(dt);
        setSections(se);
        setSectionTrigrams(st);
        setSides(si);
        setLoaded(true);
      } catch (error) {
        console.error(error);
      }
    })({ prevLastModified: lastModified });

    return () => controller.abort();
  }

  function _rehydrate() {
    const menu = JSON.parse(localStorage.getItem("menu"));
    if (menu === null) {
      _fetch();
      return;
    }
    setDishes(menu.dishes);
    setDishTrigrams(menu.dishTrigrams);
    setSections(menu.sections);
    setSectionTrigrams(menu.sectionTrigrams);
    setSides(menu.sides);
    setLoaded(true);
    _fetch({ lastModified: localStorage.getItem("menuModified") });
  }
}

export function useMenu() {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
}
