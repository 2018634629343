import { forwardRef } from "react";

import useViewport from "../lib/useViewport.js";

export default forwardRef(function Div({ children, style, ...props }, ref) {
  const { mobile } = useViewport();
  const height = style?.height || undefined;
  const width = style?.width || undefined;
  const styles = {
    alignContent: "flex-start",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0,
    height,
    justifyContent: "flex-start",
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    maxHeight: height,
    maxWidth: width,
    minHeight: height,
    minWidth: width,
    overflowX: "hidden",
    overflowY: "hidden",
    overscrollBehavior: "none",
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    width,
    ...style,
  };

  return mobile ? (
    <div ref={ref} style={styles} {...props}>
      {children}
    </div>
  ) : (
    <div ref={ref} style={styles} {...props}>
      {children}
    </div>
  );
});
